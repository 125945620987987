import Vue from 'vue'
import VueRouter from 'vue-router'

import waitHopeView from '../views/waitInHope/index.vue'
import DefaultLayout from '../layouts/default.vue'
import AdminLayout from '../layouts/admin.vue'
import ArticleView from '../views/article'
import searchResultView from '../views/search/result'
import loginView from '../views/login'
import registerView from '../views/register'
import registerResultView from '../views/register/result'
import forgotView from '../views/forgot'
import userHomeView from '../views/user/index.vue'
import userInfoView from '../views/user/info'
import submissionRecordView from '../views/user/submission/record'
import submissionStatusView from '../views/user/submission/status'
import submissionStatusDetailView from '../views/user/submission/status/detail'
import ArticleInfoEditView from '../views/user/info/components/ArticleInfoEdit'

Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/waitInHope',
    component: DefaultLayout,
    children: [{
      path: '',
      name: 'waitInHope',
      component: waitHopeView
    }],
  },
  {
    path: '/article/:id',
    component: DefaultLayout,
    children: [{
      path: '',
      name: 'article',
      component: ArticleView
    }],
  },
  {
    path: '/search/result',
    component: DefaultLayout,
    children: [{
      path: '',
      name: 'searchResult',
      component: searchResultView
    }],
  },
  {
    path: '/login',
    component: DefaultLayout,
    children: [{
      path: '',
      name: 'login',
      component: loginView
    }],
  },
  {
    path: '/register/complete',
    component: DefaultLayout,
    children: [{
      path: '',
      name: 'registerComplete',
      component: registerResultView
    }],
  },
  {
    path: '/register/verify',
    component: DefaultLayout,
    children: [{
      path: '',
      name: 'registerVerify',
      component: registerResultView
    }],
  },
  {
    path: '/register/success',
    component: DefaultLayout,
    children: [{
      path: '',
      name: 'registerSuccess',
      component: registerResultView
    }],
  },
  {
    path: '/register/failure',
    component: DefaultLayout,
    children: [{
      path: '',
      name: 'registerFailure',
      component: registerResultView
    }],
  },
  {
    path: '/register',
    component: DefaultLayout,
    children: [{
      path: '',
      name: 'register',
      component: registerView
    }],
  },
  {
    path: '/forgot',
    component: DefaultLayout,
    children: [{
      path: '',
      name: 'forgot',
      component: forgotView
    }],
  },
  {
    path: '/user',
    // name: 'user',
    component: AdminLayout,
    // redirect: '/user/info',
    children: [{
      path: '',
      component: userHomeView
    }, {
      path: '/user/info',
      name: 'userInfo',
      component: userInfoView
    }, {
      path: '/user/submission/create',
      name: 'submissionCreate',
      component: submissionRecordView
    }, {
      path: '/user/submission/body',
      name: 'submissionBody',
      component: submissionRecordView
    }, {
      path: '/user/submission/update/:id',
      name: 'submissionUpdate',
      component: submissionRecordView
    }, {
      path: '/user/submission/detail/:id',
      name: 'submissionDetail',
      component: submissionRecordView
    }, {
      path: '/user/submission/status',
      name: 'submissionStatus',
      component: submissionStatusView
    }, {
      path: '/user/attend/status',
      name: 'attendStatus',
      component: () => import('@/views/user/attend/status/index.vue')
    }, {
      path: '/user/submission/status/detail/:id',
      name: 'submissionStatusDetail',
      component: submissionStatusDetailView
    }, {
      path: '/user/attend/detail',
      name: 'attendDetail',
      component: () => import('@/views/user/attend/status/detail/index.vue')
    }, {
      path: '/user/info/update',
      name: 'ArticleInfoEdit',
      component: ArticleInfoEditView
    }]
  },
  {
    path: '/editorial',
    name: 'editorial',
    component: AdminLayout,
    redirect: '/editorial/userinfo',
    children: [{
      path: '/editorial/userinfo',
      name: 'editorIalUserInfoDetail',
      component: () => import('@/views/editorial/userinfo/index.vue')
    }, {
      path: '/editorial/userinfo/update',
      name: 'editorIalUserInfoUpdate',
      component: () => import('@/views/editorial/userinfo/index.vue')
    }, {
      path: '/editorial/thesis/list',
      name: 'editorIalThesisList',
      component: () => import('@/views/editorial/thesis/index.vue')
    }, {
      path: '/editorial/thesis/detail/:id',
      name: 'editorIalThesisDetail',
      component: () => import('@/views/editorial/thesis/detail/index.vue')
    }]
  },
  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true
  },
]

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = []

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: constantRoutes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
