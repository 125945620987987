<template>
<div class="admin-container">
  <slot />
</div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {},
  created() {},
  methods: {},
  watch: {}
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.admin-container {
  // background-color: #F0F0F0;
  min-height: 100%;
}
</style>
