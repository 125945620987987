import Vue from 'vue'
import Vuex from 'vuex'

import common from './modules/common'
import home from './modules/home'
import conferences from './modules/conferences'
import user from './modules/user'
import system from './modules/system'
import dict from './modules/dict'
import permission from './modules/permission'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    common,
    home,
    conferences,
    user,
    system,
    dict,
    permission
  }
})
