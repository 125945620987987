import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })

const whiteList = []

router.beforeEach((to, from, next) => {
  NProgress.start()
  
  store.dispatch('permission/GenerateRoutes').then((accessRoutes) => {
    accessRoutes.forEach(route => {
      router.addRoute(route)
    })

    setTimeout(() => {
      next()

      NProgress.done()
    })
  })

  next()

})