<template>
	<div class="conference">
		<div class="conference_header"></div>
		<div class="conference_box">
			<!--左侧导航栏-->
			<dl class="tabList">
				<dd :class="!$route.query.newsType ? 'active list' : 'list'"
					@click="() => doResult({ ...$route.query, newsType:null, pageNum: 1, pageSize: 10 })">全部</dd>
				<dd :class="$route.query.newsType === '2' ? 'active list' : 'list'"
					@click="() => doResult({ ...$route.query, newsType: 2, pageNum: 1, pageSize: 10 })">通知公告</dd>
				<dd :class="$route.query.newsType === '1' ? 'active list' : 'list'"
					@click="() => doResult({ ...$route.query, newsType: 1, pageNum: 1, pageSize: 10 })">新闻动态</dd>
			</dl>
			<!--左侧导航栏 end-->
			<!--右侧内容-->
			<div class="tabCon">
				<!--面包屑-->
				<div class="el-breadcrumb">
					<span class="el-breadcrumb__item">
						<span class="el-breadcrumb__inner">
							当前位置：
						</span>
					</span>
					<span class="el-breadcrumb__item">
						<span class="el-breadcrumb__inner">
							<router-link to="/" class="el-breadcrumb__inner__link"
								:class="{ 'navigation__link_active': $route.name === 'home' }">首页</router-link>
						</span>
						<i class="el-icon-arrow-right"></i>
					</span>
					<span class="el-breadcrumb__item">
						<span class="el-breadcrumb__inner">
							搜索结果
						</span>
					</span>
				</div>
				<!--面包屑 end-->
				<div class="searchForm">
					<div class="search-box">
						<input class="searchInput" type="text" placeholder="请输入关键词进行搜索" v-model="keywords">
						<span class="searchBtn" @click="handleSearch">搜索</span>
					</div>
					<div class="searchTips">找到约<span class="num">{{ news.total }}</span>条结果</div>
				</div>
				<!--全部-->
				<div>
					<dl class="news_box">
						<dd class="news_box_list" v-for="(item, index) in news.rows" :key="index" @click="() => toDetail(item)">
							<template v-if="item.newsType === 1">
								<p class="news_box_list_tit">
									<span v-html="getFormatResultHtml(item.newsTitle)"></span>
								</p>
								<p class="news_box_list_text">
								<p class="news_box_list_text_r" v-html="getFormatResultHtml(item.newsAbs)"></p>
								</p>
								<p class="news_box_list_time">{{ item.newsTimeStr }}</p>
							</template>
							<template v-if="item.newsType === 2">
								<p class="news_box_list_tit">
									<span v-html="getFormatResultHtml(item.newsTitle)"></span>
								</p>
								<p class="news_box_list_time">{{ item.newsTimeStr }}</p>
							</template>
						</dd>
					</dl>

					<!--页码-->
					<el-pagination background layout="prev, pager, next" :current-page="Number($route.query.pageNum)"
						:total="news.total" @current-change="handlePageCurrentChange"></el-pagination>
					<!--页码 end-->
				</div>
				<!--全部 end-->
			</div>
			<!--右侧内容 end-->
		</div>
	</div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
	name: 'searchResult',
	data() {
		return {
			keywords: this.$route.query.keyWord || '',
		}
	},
	computed: {
		...mapState('common', {
			'conferencesInfo': state => state.conferencesInfo
		}),
		...mapState('conferences', {
			'news': state => state.news
		})
	},
	methods: {
		...mapActions('conferences', [
			'fetchNews'
		]),
		toDetail(item) {
			this.$router.push({ path: `/article/${item.id}` })
		},
		doResult(query) {
			this.$router.push(
				{
					path: '/search/result',
					query
				},
				onComplete => { },
				onAbort => { }
			)
		},
		getFormatResultHtml(html = '') {
			return html.replace(new RegExp(this.keywords, 'g'), key => `<i style="color: red;">${key}</i>`)
		},
		handleSearch() {
			this.doResult({
				...this.$route.query,
				pageNum: 1,
				keyWord: this.keywords
			})
		},
		handlePageCurrentChange(pageNum) {
			this.doResult({
				...this.$route.query,
				pageNum
			})
		}
	},
	watch: {
		'$route.query': {
			handler(value) {
				this.fetchNews(value)
			},
			deep: true,
			immediate: true
		}
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables';

img {
	width: 100%;
	height: 100%;
}

.el-breadcrumb {
	font-size: 14px;
	line-height: 1;
	width: 100%;
	height: 50px;
	cursor: pointer;

	&:before {
		display: table;
		content: "";
	}

	.el-breadcrumb__item {
		float: left;

		.el-breadcrumb__inner {
			color: #606266;
			vertical-align: text-top;

			&__link {
				color: #606266;
				text-decoration: none;

				&:hover {
					color: $--cms-link-hover-color;
				}

				&_active {
					background-color: #027BC6;
					color: #FFF;
					border-bottom-color: $--cms-primary-color;

					&:hover {
						color: #FFF;
						opacity: .8;
					}
				}
			}

			.el-breadcrumb__separator {
				margin: 0 9px;
				font-weight: 700;
				color: #c0c4cc;
			}
		}

		.el-icon-arrow-right {
			width: 14px;
			height: 16px;
			display: inline-block;
			vertical-align: text-top;
			margin: 0 10px;
		}
	}
}

.conference {
	padding-bottom: 100px;

	&_header {
		width: 100%;
		height: 450px;
		background: url('@/assets/website/2024/uploads/conference-news-bg.jpg') no-repeat center top;
		background-size: cover;
	}

	&_box {
		width: $--cms-page-content-width1200;
		margin: 50px auto 0;

		.tabList {
			float: left;
			width: 240px;
			background: #FFFFFF;
			box-shadow: 0px 0px 4px 0px #B8B8B8;
			padding: 20px 0;

			.list {
				display: block;
				font-size: 20px;
				color: #999;
				padding: 20px 34px;
				text-align: center;
				position: relative;
				cursor: pointer;

				&:before {
					content: '';
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					bottom: 0;
					border-bottom: 1px solid #DCDCDC;
					width: 172px;
				}

				&:nth-last-child(1) {
					&:before {
						border-bottom: none;
					}

				}

				&:hover {
					color: $--cms-link-hover-color;
				}

				&.active {
					color: $--cms-primary-color;
				}
			}
		}

		.tabCon {
			width: 930px;
			background: #FFFFFF;
			box-shadow: 0px 2px 4px 0px #B8B8B8;
			margin-left: 270px;
			padding: 30px;

			.news_box {
				&_list {
					border-top: 1px solid #eee;
					padding: 30px 0;
					position: relative;
					width: 100%;
					display: inline-block;

					&:nth-last-child(1) {
						border-bottom: 1px solid #eee;
					}

					&:hover {
						color: #666;
						cursor: pointer;
					}

					&_tit {
						font-size: 20px;
						color: $--cms-color-text-base;
						margin-bottom: 15px;

						&:hover {
							color: $--cms-primary-color;
							cursor: pointer;
						}

						.img {
							width: 56px;
							height: 24px;
							vertical-align: text-top;
							display: inline-block;
							margin-left: 10px;
						}
					}

					&_text {
						margin: 0 0 0;

						&_r {
							font-size: 16px;
							color: #666;
							text-align: left;
							line-height: $--cms-line-height-middle;
						}
					}

					&_time {
						font-size: 16px;
						font-size: #666;
						text-align: right;
						position: absolute;
						bottom: 20px;
						right: 0;
					}
				}
			}

			.el-pagination {
				white-space: nowrap;
				padding: 2px 5px;
				color: #303133;
				font-weight: 700;
				margin: 50px auto;
				text-align: center;

				&:before {
					display: table;
					content: "";
				}

				button {
					display: inline-block;
					font-size: 13px;
					min-width: 35.5px;
					height: 28px;
					line-height: 28px;
					vertical-align: top;
					border: none;
					padding: 0 6px;
					background: transparent;
				}

				.btn-prev {
					margin: 0 5px;
					background-color: #f4f4f5;
					color: #606266;
					min-width: 30px;
					border-radius: 2px;
					position: relative;

					&:before {
						content: '';
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						width: 18px;
						height: 18px;
						background: url('@/assets/website/2024/assets/images/conferenceNews/leftArrow.png') no-repeat;
						background-size: 18px 18px;
					}
				}

				.btn-next {
					margin: 0 5px;
					background-color: #f4f4f5;
					color: #606266;
					min-width: 30px;
					border-radius: 2px;
					position: relative;

					&:before {
						content: '';
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						width: 18px;
						height: 18px;
						background: url('@/assets/website/2024/assets/images/conferenceNews/rightArrow.png') no-repeat;
						background-size: 18px 18px;
					}

				}

				.el-pager {
					user-select: none;
					list-style: none;
					display: inline-block;
					vertical-align: top;
					font-size: 0;
					padding: 0;
					margin: 0;

					.number {
						padding: 0 4px;
						vertical-align: top;
						display: inline-block;
						font-size: 13px;
						min-width: 35.5px;
						height: 28px;
						line-height: 28px;
						cursor: pointer;
						box-sizing: border-box;
						text-align: center;
						margin: 0 5px;
						background-color: #f4f4f5;
						color: #606266;
						min-width: 30px;
						border-radius: 2px;

						&.active {
							background-color: #409eff;
							color: #fff;
						}
					}

					.el-icon-more {
						position: relative;
						line-height: 23px;
					}
				}
			}

			.searchForm {
				margin: 30px 0 0;

				.search-box {
					.searchInput {
						display: inline-block;
						width: 610px;
						height: 36px;
						background: #FFFFFF;
						border: 1px solid #B8B8B8;
						font-size: 14px;
						color: #333;
						padding-left: 20px;
						outline: none;

						input {
							background-color: none;
							outline: none;
							border: none;
						}

						&:focus {
							border-color: $--cms-primary-color;
						}
					}

					.searchBtn {
						display: inline-block;
						width: 74px;
						height: 38px;
						line-height: 38px;
						text-align: center;
						background: #003F88;
						border: 1px solid #003F88;
						font-size: 14px;
						color: #fff;
						cursor: pointer;
					}
				}

				.searchTips {
					font-size: 20px;
					color: #999;
					margin: 50px 0 0;
					border-bottom: 2px solid $--cms-primary-color;
					padding-bottom: 16px;

					.num {
						color: $--cms-primary-color;
						margin: 0 10px;
						font-weight: bold;
					}
				}
			}
		}

	}

}
@media screen and (max-width: 767px) {
	.conference_header{
		width: 100%;
		height: 180px;
		background: url('@/assets/website/2024/uploads/conference-news-bg.jpg') no-repeat center top;
		background-size: 114% 86%;
	}
	.conference_box{
		position: relative;
		width: 100%;
	}
	.conference_box .tabList{
		position: absolute;
		left: 50%;
		top: 10px;
		width: 90%;
		transform: translateX(-50%);
		text-align: center;
		padding: 0;
	}
	.conference_box .tabList .list{
		display: inline-block;
		font-size: 1rem;
		padding: 10px 20px;
		a {
			font-size: 1rem;
			color: #333;
			text-decoration: none;
		
			&:hover {
				color: $--cms-link-hover-color;
			}
		}
		&:before {
		    content: "";
		    position: absolute;
		    top: 50%;
		    transform: translateY(-50%);
		    bottom: 0;
		    border-right: 1px solid #DCDCDC;
			border-bottom: 0;
		    width: 53%;
			height: 60%;
		}
		&:nth-last-child(1):before {
			border-bottom: none;
			border-right: 0;
		}
	}
	.conference_box .tabCon{
		margin: 82px 0 0 5%;
		width: 76%;
		display: inline-block;
		padding: 0 30px 50px;
	}
	.conference_box .tabCon .news_box_list{
		border: 0;
	}
	.conference_box .tabCon .news_box_list_tit{
		padding: 0 15px 15px;
	}
	.conference_box .tabCon .searchForm .search-box{
		display: flex;
	}
	.conference_box .tabCon .searchForm .search-box .searchInput{
		width: 68%;
	}
	.conference_box .tabCon .searchForm{
		margin: 30px 0 0;
	}
	.conference_box .tabCon .el-pagination{
		margin: 20px 0;
	}
	.conference_box .tabCon .searchForm .searchTips{
		font-size: 1.2rem;
	}
	.conference_box .tabCon .news_box_list_tit{
		font-size: 1.2rem;
		line-height: 25px;
		padding: 0 0 15px;
	}
	.el-breadcrumb{
		position: absolute;
		left: 16px;
		top: -32px;
		border: 0;
	}
}
/* 针对iOS系统的特定样式 */

@media only screen and (min-resolution: 2dppx) {
  .conference_box .tabCon .searchForm .search-box .searchInput{
	  height: 0;
	  padding: 19px;
	  border-radius: 5px 0 0 5px;
  }
}
</style>