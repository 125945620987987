import request from '@/utils/request'

// 用户登录
export function postLogin(data) {
  return request({
    url: '/portal/puser/login',
    method: 'POST',
    data
  })
}

// 用户注册
export function postRegister(data) {
  return request({
    url: '/portal/puser/register',
    method: 'POST',
    data
  })
}

// 获取用户信息
export function fetchUserInfo(params) {
  return request({
    url: '/portal/puser/userInfo',
    method: 'GET',
    params
  })
}

// 获取图形验证码
export function fetchImgCode(params) {
  return request({
    url: '/code',
    method: 'GET',
    params
  })
}

// 发送短信
export function postSendSms(data) {
  return request({
    url: '/portal/puser/sendSms',
    method: 'POST',
    data
  })
}

// 发送找回密码验证信息
export function postSendMsg(data) {
  return request({
    url: '/portal/puser/findVerify',
    method: 'POST',
    data
  })
}

// 重置密码
export function postResetPwd(data) {
  return request({
    url: '/portal/puser/resetPwd',
    method: 'POST',
    data
  })
}
// 修改用户基本信息(已登录)
export function postUpdateDetail(data) {
  return request({
    url: '/portal/puser/updateDetail',
    method: 'POST',
    data
  })
}
// 发送邮件验证码（已登录）
export function fetchSendEcode(params) {
  return request({
    url: '/portal/puser/sendEcode',
    method: 'GET',
    params
  })
}

// 修改用户基本信息(已登录)
export function postUpdateInfo(data) {
  return request({
    url: '/portal/puser/updateDetail',
    method: 'POST',
    data
  })
}

// 邮箱验证
export function getVerifyRegister({ uuid }) {
  return request({
    url: `/portal/puser/verifyregister/${uuid}`,
    method: 'GET'
  })
}
