import {
  postRegister,
  postLogin,
  fetchUserInfo,
  fetchImgCode,
  postSendSms,
  postSendMsg,
  postResetPwd,
  postUpdateInfo,
  fetchSendEcode,
  postUpdateDetail
} from '@/services/puser'
import Cookies from 'js-cookie'

// initial state
// shape: [{ id, quantity }]
const state = () => ({
  // 用户TOKEN
  token: {
    // access_token: Cookies.get('Authorization') || localStorage.getItem('access_token'),
    access_token: Cookies.get('Authorization')
    // expires_in: 0,
    // expires_time: localStorage.getItem('expires_time')
  },
  userInfo: {
    userName: '',
    roles: []
  },
  imgAuth: {
    // 图形验证码 uuid
    uuid: '',
    // 图形验证码图片
    img: ''
  },
})

// getters
const getters = {
  accessToken: state => {
    // return state.token.access_token || Cookies.get('Authorization') || localStorage.getItem('access_token')
    // return state.token.access_token || Cookies.get('Authorization')
    return state.token.access_token
  },
  /* expiresTime: (state) => {
    const expirseTime = state.token.expires_time || localStorage.getItem('expires_time')

    return expirseTime ? Number(expirseTime) : 0
  },
  isInExpires: (state, getters) => {
    return +new Date() < getters.expiresTime
  } */
  roleNames: state => {
    return state.userInfo.roles.map(role => role.roleKey)
  }
}

// actions
const actions = {
  // 提交注册
  async postRegister({ commit, state }, payload) {
    try {
      const data = await postRegister(payload)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 存储 token
  saveToken({ commit, state }, payload) {
    // localStorage.setItem('access_token', `Bearer ` + payload['access_token'])
    // localStorage.setItem('expires_in', payload['expires_in'])
    // localStorage.setItem('expires_time', +new Date() + payload['expires_in'] * 60 * 1000)
  },
  // 获取 token
  getToken({ commit, state }, payload) {
    const access_token = localStorage.getItem('access_token')

    return access_token
  },
  // 清除 token
  clearToken({ commit, state }, payload) {
    commit('setToken', {
      access_token: '',
      expires_in: 0
    })

    localStorage.clear()
  },
  // 清除用户信息
  clearUserInfo({ commit, state }, payload) {
    commit('setUserInfo', {
      userName: ''
    })
  },
  // 获取用户信息
  async fetchUserInfo({ commit, state }, payload) {
    try {
      const data = await fetchUserInfo(payload)

      commit('setUserInfo', data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 登录
  async doLogin({ commit, state }, payload) {
    try {
      const data = await postLogin(payload)

      commit('setToken', data)

      const access_token = `Bearer ` + data['access_token']

      /* localStorage.setItem('access_token', access_token)
      localStorage.setItem('expires_in', data['expires_in'])
      localStorage.setItem('expires_time', +new Date() + data['expires_in'] * 60 * 1000) */

      Cookies.set('Authorization', data['access_token'], { expires: data['expires_in'] * 60 * 1000 })

      return Promise.resolve(data)
    } catch (e) {
      return new Promise.reject(e)
    }
  },
  // 登出
  async doLogout({ dispatch, commit }) {
    await dispatch('clearToken')

    Cookies.remove('Authorization')

    commit('setUserInfo', {
      userName: ''
    })
  },
  // 获取图形验证码
  async fetchImgCode({ commit, state }, payload) {
    try {
      const data = await fetchImgCode()

      commit('setImgAuth', data || { img: '', uuid: '' })

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  // 发送短信验证码
  async postSendSms({ commit, state }, payload) {
    try {
      const data = await postSendSms(payload)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  // 发送找回密码验证信息
  async postSendMsg({ commit, state }, payload) {
    try {
      const data = await postSendMsg(payload)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  // 重置密码
  async postResetPwd({ commit, state }, payload) {
    try {
      const data = await postResetPwd(payload)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 修改用户基本信息
  async postUpdateInfo({ commit, state }, payload) {
    try {
      const data = await postUpdateInfo(payload)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  //发送邮箱验证码
  async fetchSendEcode({ commit, state }, payload) {
    try {
      const data = await fetchSendEcode(payload)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 修改用户基本信息
  async postUpdateDetail({ commit, state }, payload) {
    try {
      const data = await postUpdateDetail(payload)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },

}

// mutations
const mutations = {
  // 设置用户 token
  setToken(state, payload) {
    state.token = payload
  },
  // 设置用户信息
  setUserInfo(state, payload) {
    state.userInfo = payload
  },
  // 设置 UUID
  setUuid(state, payload) {
    state.uuid = payload
  },
  // 设置图形验证码
  setImgAuth(state, payload) {
    state.imgAuth = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}