<template>
    <div class="header__body">
		<el-row type="flex" justify="end" class="header__body_mr30">
			<Space>
			   <el-button :type="thesisDateStatus !== 1 ? 'info' : 'primary'" :disabled="thesisDateStatus !== 1" @click="handleNewSubmission">投新稿件</el-button>
		   </Space>
	   </el-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    components: {
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState('system', {
            'thesisDateStatus': state => state.thesisDateStatus
        }),
    },
    methods: {
      handleNewSubmission() {
        this.$router.push({
          path: '/user/submission/create'
        })
      }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.header__body {
    padding:20px 0;
	&_mr30{
		margin-right: 30px;
	}
	.el-button{
		width: 110px!important;
	}
}

</style>
