<template>
  <div class="page-header-wrap">
    <div class="page-header">
      <div class="page-header__content">
        <h1 class="logo"><a href="/"><img :src="conferencesInfo && conferencesInfo.logoUrl"
              :alt="conferencesInfo.title" /></a></h1>
      </div>

      <template v-if="layout === 'default'">
        <div class="search">
          <div class="search-mask" show-in-sm v-if="searchVisible" @click="searchVisible = false"></div>

          <i class="el-icon-search" @click="searchVisible = !searchVisible"></i>

          <div class="search-inner" :class="{ 'search-inner-show': searchVisible }">
            <input type="text" class="input" placeholder="请输入关键词进行搜索" v-model="keywords" />
            <button class="button" @click="handleSearch">搜索</button>
          </div>
        </div>
      </template>

      <template v-if="layout === 'admin'">
        <router-link class="gohome" to="/"><i class="el-icon-s-home"></i></router-link>
      </template>

      <div class="page-header__menu" :class="{ 'page-header__menu_show': menuVisible }">
        <i class="page-header__menu-icon" @click="() => {
          menuVisible = !menuVisible;
          searchVisible = false;
        }"></i>
        <div class="page-header__menu-mask" @click="menuVisible = false"></div>
        <div class="page-header__menu-layer">
          <i class="page-header__menu-close el-icon-close" @click="menuVisible = false"></i>

          <div class="page-header__menu-avatar"></div>

          <ul class="actions">
            <template v-if="userInfo.userName">
              <li class="action__item">{{ userInfo.userName }}</li>
              <li class="action__item">
                <router-link to="/user">用户中心</router-link>
              </li>
              <li class="action__item action__logout" show-in-lg>
                <el-popover placement="bottom" width="160" v-model="logoutVisible">
                  <p>确定登出？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="logoutVisible = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="() => doLogout()">确定</el-button>
                  </div>
                  <a href="javascript:;" class="action__link" slot="reference">退出登录</a>
                </el-popover>
              </li>
              <li class="action__item m-action__logout" show-in-sm>
                <a href="javascript:;" class="action__link" @click="() => {
                  $dialog.confirm({
                    title: '提示',
                    message: '确定要退出登录？',
                  })
                    .then(() => {
                      doLogout();

                      $message.success('已退出登录');

                      menuVisible = false;

                      $router.push({
                        path: '/'
                      }, () => {})
                    })
                    .catch(() => {
                      // on cancel
                    })

                }">退出登录</a>
              </li>
            </template>
            <template v-else>
              <li class="action__item" @click="menuVisible = false">
                <router-link to="/register" class="action__link">网站注册</router-link>
              </li>
              <li class="action__item" @click="menuVisible = false">
                <router-link to="/login" class="action__link">登录</router-link>
              </li>
            </template>
            <!-- <li class="action__item"><a href="#" class="action__link action__link_danger">ENGLISH</a></li> -->
          </ul>

          <div @click="menuVisible = false">
            <slot name="menu"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'

export default {
  props: {
    layout: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      keywords: '',
      logoutVisible: false,
      searchVisible: false,
      menuVisible: false
    }
  },
  computed: {
    ...mapState('common', {
      'conferencesInfo': state => state.conferencesInfo
    }),
    ...mapState('user', {
      'userInfo': state => state.userInfo
    }),
  },
  async created() {
    this.logoutVisible = false
  },
  methods: {
    ...mapActions('user', [
      'doLogout'
    ]),
    handleSearch() {
      if (!this.keywords || this.keywords === this.$route.query.keywords) {
        return false
      }

      this.searchVisible = false

      this.$router.push({
        path: '/search/result',
        query: {
          id: this.conferencesInfo.id,
          keyWord: this.keywords,
          pageNum: 1,
          pageSize: 10
        }
      }, () => { })
    },
    handleLogout() {
      this.logoutVisible = false

      this.doLogout()
    },
    hide() {
      this.menuVisible = false
    }
  },
  watch: {
    '$route'() {
      this.logoutVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.logo {
  position: relative;
  top: 60px;
}

.page-header {
  position: relative;
  margin: 0 auto;
  zoom: 1;
  width: $--cms-page-wrapper-width;
  min-height: 150px;

  &-wrap {
    border-bottom: 1px solid #EEEEEE;
    background: #FFF;
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  &__content {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // padding: 0 0 0 100px;
    margin: 0 auto;
    position: relative;
    min-height: 170px;
  }

  .actions {
    position: absolute;
    top: 20px;
    right: 0;
  }

  .action {
    &__item {
      float: left;
      font-size: $--cms-font-size;
      color: #666;
      line-height: $--cms-line-height;
      font-weight: bold;

      a {
        color: #666;
        text-decoration: none;

        &:hover {
          color: $--cms-link-hover-color;
        }
      }

      &::before {
        content: '';
        width: 0;
        height: 15px;
        border-left: 1px solid #666;
        margin: 0 14px;
        display: inline-block;
        vertical-align: middle;
      }

      &:first-of-type {
        &::before {
          display: none;
        }
      }
    }

    &__link {
      text-decoration: none;
      font-size: $--cms-font-size;
      color: #666;
      line-height: $--cms-line-height;
      font-weight: bold;
      transition: all $--cms-motion-duration-mid $--cms-motion-ease-in-out;

      &:hover {
        color: $--cms-link-hover-color;
      }
    }

    &__link_danger {
      color: #E84B4C;
    }
  }

  .navigation {
    // margin-left: 100px;
    text-align: center;

    &__item {
      float: left;
    }

    &__link {
      display: block;
      text-decoration: none;
      font-size: $--cms-font-size-lg;
      line-height: 1.4;
      font-weight: bold;
      min-width: 98px;
      padding: 23px 26px;
      color: $--cms-primary-color;
      border-bottom: 6px solid transparent;
      transition: all $--cms-motion-duration-mid $--cms-motion-ease-in-out;
      text-align: center;

      &:hover {
        color: $--cms-link-hover-color;
      }

      &_active {
        background-color: #027BC6;
        color: #FFF;
        border-bottom-color: $--cms-primary-color;

        &:hover {
          color: #FFF;
          opacity: .8;
        }
      }
    }
  }
}

.page-header__menu {
  .el-icon-close {
    display: none;
  }
}

// 回首页
.gohome {
  position: absolute;
  top: 4.8vw;
  right: 16vw;
  font-size: 6.6vw;
  color: $--cms-primary-color;
}

.search {
  position: absolute;
  right: 0;
  top: 76px;

  .el-icon-search {
    display: none;
  }

  .input,
  .button {
    float: left;
  }

  .input {
    width: 300px - 12px * 2;
    height: 34px;
    background: #FFFFFF;
    border: 1px solid #B8B8B8;
    font-weight: 400;
    font-size: $--cms-font-size;
    color: #999999;
    line-height: 20px;
    padding: 0 12px;
    border-right: 0;
    outline: none;

    &:focus {
      border-color: $--cms-primary-color;
    }
  }

  .button {
    width: 74px;
    height: 36px;
    background: $--cms-primary-color;
    border: 1px solid $--cms-primary-color;
    line-height: 36px;
    text-align: center;
    font-size: $--cms-font-size;
    color: #FFF;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }
}

@media screen and (max-width: 1400px) {
  .page-header {
    width: $--cms-page-content-width;
  }
}

@media screen and (max-width: 767px) {
  .page-header-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #FFF;
    height: 15vw;
  }

  .page-header__content {
    padding: 0;
    min-height: auto;
  }

  // 图标
  .logo {
    top: 3.3vw;
    left: 0;
    height: 9.3vw;

    img {
      height: 100%;
    }
  }

  // 搜索
  .search {
    top: 5vw;
    right: 22vw;

    .el-icon-search {
      display: block;
      position: absolute;
      font-size: 6vw;
      font-weight: 600;
      color: $--cms-primary-color;
    }

    .input {
      border-color: #EEE;
    }

    &-inner {
      position: fixed;
      top: 15vw;
      left: 0;
      right: 0;
      display: none;
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1);

      .input {
        flex: 1;
      }

      &-show {
        display: flex;
      }
    }

    &-mask {
      position: fixed;
      top: calc(15vw + 36px);
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .8);
    }
  }

  .page-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    min-height: auto;
    max-width: 100%;

    // 登录状态
    .actions {
      position: relative;
      top: 0;
    }
  }

  .page-header__menu {

    // 按钮
    &-icon {
      position: absolute;
      top: 5vw;
      right: 6.67vw;
      width: 5.87vw;
      height: 5.87vw;
      background: url('@/assets/images/msite/m-header-menu-icon.png') no-repeat 0 0;
      background-size: contain;
    }

    // 遮罩
    &-mask {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 101;
      background-color: rgba(0, 0, 0, .8);
      display: none;
    }

    // 弹层
    &-layer {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 102;
      background-color: $--cms-primary-color;
      display: none;
      padding-bottom: 20px;
      overflow-y: auto;
      max-height: 100vh;
      box-sizing: border-box;
    }

    // 关闭按钮
    .el-icon-close {
      position: fixed;
      top: 3vw;
      right: 3vw;
      color: #FFF;
      font-size: 20px;
      display: block;
    }

    // 导航
    .navigation {
      margin: 0;

      &__item {
        width: 100%;
      }

      &__link {
        font-weight: 600;
        font-size: 22px;
        color: #FFFFFF;
        line-height: 30px;
        padding: 0;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;

        &_active {

          &,
          &:hover {
            background-color: #FFF;
            color: $--cms-primary-color;
            opacity: 1;
          }
        }
      }
    }

    // 头像
    &-avatar {
      width: 84px;
      height: 84px;
      background: url('@/assets/images/user/avatar-white.png') no-repeat center 0;
      background-size: contain;
      margin: 50px auto 30px;
    }

    // 菜单显示
    &_show {
      .page-header__menu-mask {
        display: block;
      }

      .page-header__menu-layer {
        display: block;
      }

      // 导航
      .navigation {
        max-height: calc(100vh - 200px);
        // overflow-y: auto;
      }

      // 注册登录
      .actions {
        display: flex;
        justify-content: center;
        margin-bottom: 34px;
      }

      .action__item {
        float: none;

        &::before {
          border-color: #FFF;
        }

        &,
        a {
          font-size: 18px;
          color: #FFF;
        }
      }
    }
  }
}
</style>
