import { constantRoutes } from '@/router'
import Layout from '@/layouts/default.vue'
import AdminLayout from '@/layouts/admin.vue'

const permission = {
  namespaced: true,
  state: {
    routes: [],
    addRoutes: [],
    defaultRoutes: [],
    topbarRouters: [],
    sidebarRouters: []
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
      state.routes = constantRoutes.concat(routes)
    },
    SET_DEFAULT_ROUTES: (state, routes) => {
      state.defaultRoutes = constantRoutes.concat(routes)
    },
    SET_TOPBAR_ROUTES: (state, routes) => {
      state.topbarRouters = routes
    },
    SET_SIDEBAR_ROUTERS: (state, routes) => {
      state.sidebarRouters = routes
    },
  },
  actions: {
    // 生成路由
    async GenerateRoutes({ commit, dispatch, rootState }, payload) {
      try {
        let conferenceInfo = {}
        let routes = []

        if (!rootState.conferences.channels.length) {
          conferenceInfo = await dispatch('common/fetchConferencesInfo', null, { root: true })
          routes = await dispatch('conferences/fetchConferencesChannels', { conferencesId: conferenceInfo.id }, { root: true })
        }
        
        const rewriteRoutes = filterAsyncRouter(routes, false, true)

        return Promise.resolve(rewriteRoutes)
      } catch (e) {
        return Promise.reject(e)
      }
    }
  }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter(route => route.component || route.layout).map(route => {
    const raw = { ...route }

    route = {
      path: route.path,
      name: route.name,
      component: route.component,
      meta: {
        raw
      }
    }

    const { path, name, component } = route

    if (route.meta.raw.layout) {
      route.path = path.replace(/\?.*/, '')
      route.name = name
      route.component = loadView(route.meta.raw.layout, 'layouts')

      if (component) {
        route.children = [{
          path: '',
          name,
          meta: {
            raw
          },
          component: loadView(component)
        }]

        delete route.name
      }
    } else {
      route.path = path.replace(/\?.*/, '')
      route.name = name
      route.component = loadView(component)
    }

    return route
  })
}

export const loadView = (view, dir = 'views') => {
  if (process.env.NODE_ENV === 'development') {
    return (resolve) => require([`@/${dir}/${view}`], resolve)
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return () => import(`@/${dir}/${view}`)
  }
}

export default permission
