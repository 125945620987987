<template>
  <div class="admin-container-content">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AdminContainerContent'
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.admin-container-content {
  background-color: #FFFFFF;
  padding: 20px 20px;
}
</style>
