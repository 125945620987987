<template>
<div class="header__body">
  <div class="content">
    <img class="img" src="https://s21.ax1x.com/2024/05/29/pk3lerF.png" />

    <p class="btn" v-if="thesisDateStatus === 1">
      <router-link to="/user/submission/create">
        <img src="https://s21.ax1x.com/2024/05/29/pk3luVJ.png" />
      </router-link>
    </p>
  </div>
</div>
</template>

<script>
import {
  mapState
} from 'vuex'

export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapState('system', {
      'thesisDateStatus': state => state.thesisDateStatus
    }),
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

img {
  width: 100%;
  height: 100%;
}

.header__body {
  position: relative;

  .content {
    position: absolute;
    left: 50%;
    top: 172px;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;

    .img {
      display: inline-block;
      width: 124px;
      height: 198px;
    }

    .btn {
      width: 318px;
      height: 88px;
      margin: 31px auto 0;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }
  }

}
</style>
