import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as ElementUI from 'element-ui'
import { Icon, Button, Step, Steps, Swipe, SwipeItem, Dialog, List, Cell, CellGroup } from 'vant'
import Space from '@/components/Space'
import { getDicts } from '@/services/system/dict/data'

import './permission' // permission control

// 字典标签组件
import DictTag from '@/components/DictTag'

// 字典数据组件
import DictData from '@/components/DictData'

import CommonBanner from '@/components/CommonBanner'

import 'element-ui/lib/theme-chalk/index.css'
import 'vant/lib/index.css';
import 'normalize.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import './styles/reset.scss'

Vue.config.productionTip = false

// 全局方法挂载
Vue.prototype.getDicts = getDicts

Vue.use(ElementUI)
Vue.use(Icon)
Vue.use(Button)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Dialog)
Vue.use(List)
Vue.use(Cell)
Vue.use(CellGroup)

Vue.component('Space', Space)
Vue.component('DictTag', DictTag)
Vue.component('CommonBanner', CommonBanner)

DictData.install()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
