<template>
  <div class="page">
    <Header />
    <ArticleInfo />
    <AuthorInfo />
    <ArticleAnnex />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import Header from '../components/Header/index.vue'
import ArticleInfo from '../components/ArticleInfo/index.vue'
import AuthorInfo from '../components/AuthorInfo/index.vue'
import ArticleAnnex from '../components/ArticleAnnex/index.vue'

export default {
  name: 'submissionStatusDetail',
  components: {
    Header,
    ArticleInfo,
    AuthorInfo,
    ArticleAnnex
  },
  data() {
    return {}
  },
  computed: {
  },
  async created() {
    try {
      if (this.$route.params.id) {
        const data = await this.getSystemThesisThesisInfo({
          thesisId: this.$route.params.id
        })

        this.setThesisInfo({
          ...data
        })
      }
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    ...mapActions('system', [
      'getSystemThesisThesisInfo'
    ]),
    ...mapMutations('system', {
      setThesisInfo: 'setThesisInfo' // 将 `this.add()` 映射为 `this.$store.commit('increment')`
    }),
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.page {
  background: #f0f0f0;
  height: 100%;
}
</style>
