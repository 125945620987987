<template>
  <div id="app">
    <div v-if="loading" class="loading">
      <div class="loading-spin"></div>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions
} from 'vuex'

export default {
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState('common', {
      'conferencesInfo': state => state.conferencesInfo
    }),
    ...mapGetters('user', [
      'accessToken'
    ])
  },
  async created() {
    this.loading = true

    try {
      const conferencesInfo = await this.fetchConferencesInfo()

      await this.fetchConferencesChannels({
        conferencesId: conferencesInfo.id,
      })

      // await this.fetchDictTypeByKeys()

      if (this.accessToken && this.$route.name !== 'login') {
        await this.fetchUserInfo()
      }

      this.loading = false
    } catch (e) {
      console.error(e)

      this.loading = true
    }
  },
  methods: {
    ...mapActions('common', [
      'fetchConferencesInfo'
    ]),
    ...mapActions('user', [
      'fetchUserInfo'
    ]),
    ...mapActions('conferences', [
      'fetchConferencesChannels'
    ]),
    ...mapActions('system', [
      // 'fetchDictTypeByKeys'
    ])
  }
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

.el-button--primary {
  background-color: $--cms-primary-color;
  border-color: $--cms-primary-color;

  &:hover {
    background-color: $--cms-link-hover-color;
    border-color: $--cms-link-hover-color;
  }

  &.is-plain {
    background-color: transparent;
    border-color: $--cms-primary-color;
    color: $--cms-primary-color;

    &:hover {
      border-color: $--cms-link-hover-color;
      color: $--cms-link-hover-color;
      background-color: transparent;
    }
  }
}

[show-in-sm] {
  display: none !important;
}

[show-in-lg] {
  display: block !important;
}

$arrDisplay: block,
  inline-block,
  inline,
  flex,
  grid,
  table;

@each $display in $arrDisplay {
  [show-in-lg="#{$display}"] {
    display: $display !important;
  }

  [alt="show-in-lg='#{$display}'"] {
    display: $display !important;
  }
}

[hide-in-sm] {
  display: block !important;
}

[alt="hide-in-sm"] {
  display: block !important;
}

@each $display in $arrDisplay {
  [show-in-sm="#{$display}"] {
    display: none !important;
  }

  [hide-in-sm="#{$display}"] {
    display: $display !important;
  }

  [alt="hide-in-sm='#{$display}'"] {
    display: $display !important;
  }

  [hide-in-lg="#{$display}"] {
    display: none !important;
  }

  [alt="hide-in-lg='#{$display}'"] {
    display: none !important;
  }
}

[hide-in-lg] {
  display: none !important;
}

[alt="hide-in-lg"] {
  display: none !important;
}

@media screen and (max-width: 767px) {
  [show-in-sm] {
    display: block !important;
  }

  [show-in-lg] {
    display: none !important;
  }

  [alt="show-in-lg"] {
    display: none !important;
  }

  @each $display in $arrDisplay {
    [show-in-sm="#{$display}"] {
      display: $display !important;
    }

    [show-in-lg="#{$display}"] {
      display: none !important;
    }

    [alt="show-in-lg='#{$display}'"] {
      display: none !important;
    }
  }

  [hide-in-sm] {
    display: none !important;
  }

  [alt="hide-in-sm"] {
    display: none !important;
  }

  [hide-in-lg] {
    display: block !important;
  }

  [alt="hide-in-lg"] {
    display: block !important;
  }

  @each $display in $arrDisplay {
    [hide-in-lg="#{$display}"] {
      display: $display !important;
    }
    
    [alt="hide-in-lg='#{$display}'"] {
      display: $display !important;
    }

    [alt="hide-in-sm='#{$display}'"] {
      display: none !important;
    }
  }
}
</style>
<style lang="scss" scoped>
@import '@/styles/variables.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  min-height: 100vh;
}

.loading {
  position: relative;
  width: 100vw;
  height: 100vh;

  &-spin {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 470px;
    height: 71px;

    &::before,
    &::after {
      content: '';
      display: inline-block;
      height: 71px;
      background-repeat: no-repeat;
      background-image: url('@/assets/website/2024/assets/images/common/loading.png')
    }

    &::before {
      width: 71px;
      background-position: -10px 0;
      animation: rotate 10s linear infinite;
    }

    &::after {
      width: 389px;
      background-position: -81px 0;
    }
  }
}

::v-deep {
  .el-button--primary {
    background-color: $--cms-primary-color;
    border-color: $--cms-primary-color;

    &:hover {
      background-color: $--cms-link-hover-color;
      border-color: $--cms-link-hover-color;
    }

    &.is-plain {
      background-color: #fff;
      border-color: $--cms-primary-color;
      color: $--cms-primary-color;

      &:hover {
        border-color: $--cms-link-hover-color;
        color: $--cms-link-hover-color;
        background-color: #fff;
      }

      &.is-disabled {
        color: lighten($--cms-primary-color, 60%);
        background-color: lighten($--cms-primary-color, 90%);
        border-color: lighten($--cms-primary-color, 60%);
      }
    }
  }
}

::v-deep {
  .form-item__imgcode {
    .el-input-group__append {
      padding: 0;
    }
  }

  .form-item__imgcode-img {
    display: block;
    width: 100px;
    height: 38px;
    cursor: pointer;
  }

  .form__footer {
    a {
      color: $--cms-primary-color;
      text-decoration: none;

      &:hover {
        color: $--cms-link-hover-color;
      }
    }

    .el-button {
      flex: 1;
    }

    &-row {

      &,
      &_end {
        display: flex;
        width: 100%;
      }

      &_end {
        justify-content: flex-end;
      }

      &_between {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

::v-deep {
  div.el-breadcrumb {
    width: auto;
  }
}

::v-deep {
  .steps {
    &.el-steps--horizontal {
      width: calc(100% - 8em);
    }

    .el-step__icon {
      width: 32px;
      height: 32px;
      background: rgba(0, 0, 0, 0.06);
    }

    .el-step__head.is-wait {
      border-color: rgba(0, 0, 0, 0.06);
    }

    .el-step__icon.is-text {
      border: none;
    }

    .el-step.is-horizontal .el-step__line {
      top: 16px;
      left: 100px;
      right: 10px;
    }

    .el-step__head.is-finish {
      color: #FFFFFF;
      border-color: $--cms-primary-color;

      .el-step__icon {
        background: $--cms-primary-color;
      }
    }

    .el-step__title {
      position: absolute;
      top: 5px;
      left: 32px;
      font-weight: 500;
      font-size: $--cms-font-size-lg;
      line-height: 24px;
      background: #F0F0F0;
      padding: 0 10px;
      white-space: nowrap;
      color: rgba(0, 0, 0, 0.45);
    }

    .el-step__title.is-finish {
      color: $--cms-primary-color;
    }


    .el-step__head.is-process .el-step__icon {
      background: #FFFFFF;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  .loading-spin {
    width: 62.67vw;
    height: 9.47vw;

    &::before,
    &::after {
      height: 100%;
      background-size: auto 100%;
    }

    &::before {
      width: 9.47vw;
      background-position: -1.33vw;
    }

    &::after {
      width: 51.87vw;
      background-position: -10.8vw;
    }
  }
}
</style>
