<template>
	<div class="article__body">
		<div class="article__body-bigTit">论文附件</div>
		<template>
			<el-table class="article__body-table" :data="fileList" border
				:header-cell-style="{ background: '#f0f0f0' }">
				<el-table-column label="文件类型" width="150">
					<template slot-scope="{row}">
						<span>{{ row.fileType }}</span>
					</template>
				</el-table-column>
				<el-table-column label="名称">
					<template slot-scope="{row}">
						<span>{{ row.fileName }}</span>
					</template>
				</el-table-column>
				<el-table-column label="文件大小" width="150">
					<template slot-scope="{row}">
						<span>{{ row.fileSize }}</span>
					</template>
				</el-table-column>
				<el-table-column label="上传时间" width="200">
					<template slot-scope="{row}">
						<span>{{ row.createTime | filterDateStr }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="150" class-name="small-padding fixed-width">
					<template slot-scope="{row,$index}">
						<a :href="`${baseURL}/system/thesis/downloadFile?key=${row.fileUrl}`" :title="`${baseURL}/system/thesis/downloadFile?key=${row.fileUrl}`" target="_blank"><el-button type="primary" size="mini">下载</el-button></a>
					</template>
				</el-table-column>
			</el-table>
		</template>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import { filterDateStr } from '@/filters'
import { baseURL } from '@/utils/request'

const fileNameReg = /([^\/]+)\.\w+$/

export default {
	filters: {
		filterDateStr
	},
	components: {
	},
	data() {
		return {
			baseURL
		}
	},
	computed: {
		...mapState('system', {
			'thesisInfo': state => state.thesisInfo
		}),
		fileList() {
			const list = []

			if (this.thesisInfo.absDocUrl) {
				list.push({
					fileType: '论文摘要',
					fileName: fileNameReg.test(this.thesisInfo.absDocUrl) ? decodeURIComponent(this.thesisInfo.absDocUrl.match(fileNameReg)[1]) : (this.thesisInfo.absDocUrl || '--'),
					fileSize: this.thesisInfo.absDocLen ? `${(this.thesisInfo.absDocLen / 1024 / 1024).toFixed(2)}MB` : '0',
					createTime: this.thesisInfo.absDocTime ? dayjs(this.thesisInfo.absDocTime).format('YYYY-MM-DD HH:mm:ss') : '--',
					fileUrl: this.thesisInfo.absDocUrl
				})
			}

			if (this.thesisInfo.secrecyDocUrl) {
				list.push({
					fileType: '保密证明',
					fileName: fileNameReg.test(this.thesisInfo.secrecyDocUrl) ? decodeURIComponent(this.thesisInfo.secrecyDocUrl.match(fileNameReg)[1]) : (this.thesisInfo.secrecyDocUrl || '--'),
					fileSize: this.thesisInfo.secrecyDocLen ? `${(this.thesisInfo.secrecyDocLen / 1024 / 1024).toFixed(2)}MB` : '0',
					createTime: this.thesisInfo.secrecyDocTime ? dayjs(this.thesisInfo.secrecyDocTime).format('YYYY-MM-DD HH:mm:ss') : '--',
					fileUrl: this.thesisInfo.secrecyDocUrl
				})
			}

			if (this.thesisInfo.copyrightDocUrl) {
				list.push({
					fileType: '版权协议',
					fileName: fileNameReg.test(this.thesisInfo.copyrightDocUrl) ? decodeURIComponent(this.thesisInfo.copyrightDocUrl.match(fileNameReg)[1]) : (this.thesisInfo.copyrightDocUrl || '--'),
					fileSize: this.thesisInfo.copyrightDocLen ? `${(this.thesisInfo.copyrightDocLen / 1024 / 1024).toFixed(2)}MB` : '0',
					createTime: this.thesisInfo.copyrightDocTime ? dayjs(this.thesisInfo.copyrightDocTime).format('YYYY-MM-DD HH:mm:ss') : '--',
					fileUrl: this.thesisInfo.copyrightDocUrl
				})
			}

			if (this.thesisInfo.bodyDocUrl) {
				list.push({
					fileType: '论文全文',
					fileName: fileNameReg.test(this.thesisInfo.bodyDocUrl) ? decodeURIComponent(this.thesisInfo.bodyDocUrl.match(fileNameReg)[1]) : (this.thesisInfo.bodyDocUrl || '--'),
					fileSize: this.thesisInfo.bodyDocLen ? `${(this.thesisInfo.bodyDocLen / 1024 / 1024).toFixed(2)}MB` : '0',
					createTime: this.thesisInfo.bodyDocTime ? dayjs(this.thesisInfo.bodyDocTime).format('YYYY-MM-DD HH:mm:ss') : '--',
					fileUrl: this.thesisInfo.bodyDocUrl
				})
			}

			return list
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.article__body {
	background: #fff;
	padding: 20px;
	margin-top: 20px;

	&-bigTit {
		font-size: 20px;
		color: #003F88;
		line-height: 28px;
		font-weight: 500;
	}

	&-table {
		margin-top: 30px;
		color: #000;
	}
}

::v-deep {
	.el-table .el-table__cell {
		font-size: 14px;
		font-weight: 400;
	}

	.el-table th.el-table__cell {
		font-size: 14px;
		font-weight: 500;
		color: #000;
	}

	.el-table .el-table__body td,
	.el-table .el-table__header th {
		border-color: #DCDCDC;
	}
}
</style>
