import axios from 'axios'
import { Message } from 'element-ui'
import router from '../router'
import _ from 'lodash'

// 获取请求前缀
export const baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL : ''

const debounceMessage = _.debounce((options) => {
  Message(options)
}, 500)

// 创建 axios 实例
const service = axios.create({
  baseURL,
  timeout: 2 * 60 * 1000,
  headers: { 'Content-Type': 'application/json; charset=utf-8' }
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // const access_token = localStorage.getItem('access_token')

    // config.headers['Authorization'] = access_token

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    if (response.status >= 500) {
      debounceMessage({
        type: 'error',
        message: response.statusText || '服务器错误'
      })

      return Promise.reject(new Error(response.statusText || '服务器错误'))
    }

    const { code, msg, data, skip } = response.data || {}

    // 需要登录
    if (code === 401) {
      window.location.href = `/login?redirect=${router.history.current.fullPath}`
    }

    // 成功响应
    if (code === 200) {
      return data
    }

    if (code !== 200 && !skip) {
      debounceMessage({
        type: 'error',
        message: msg
      })

      return Promise.reject(new Error(msg || 'Error'))
    }

    // 响应数据为二进制流处理(Excel导出)
    if (data instanceof ArrayBuffer) {
      return response
    }

    return Promise.reject(new Error(msg || 'Error'))
  },
  (error) => {
    if (!error.response) {
      debounceMessage({
        type: 'error',
        message: `接口无响应`
      })
    } else if (error.response.data) {
      const { code, msg } = error.response.data

      debounceMessage({
        type: 'error',
        message: msg || error.message
      })
    }

    return Promise.reject(error.message)
  }
)

// 导出 axios 实例
export default service
