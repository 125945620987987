<template>
<div class="page-footer">
  <div class="page-footer__content">
    <div class="page-footer__head">
      <dl class="links">
        <dt class="title">站内链接</dt>
        <dd class="item" v-for="(item, index) in sitemap" :key="index">
          <a :href="item.url">{{ item.title }}</a>
        </dd>
      </dl>
      <dl class="links friends">
        <dt class="title">友情链接</dt>
        <dd class="item" v-for="(item, index) in conferencesInfo.footer.links" :key="index"><a :href="item.linkUrl" target="_blank">{{ item.linkWord }}</a></dd>
      </dl>
      <dl class="links contact">
        <dt class="title">联系方式</dt>
        <dd class="item" v-for="(item, index) in conferencesInfo.footer.conn" :key="index">
          <template v-if="item.connType">
            <span class="group-title">{{ item.connType }}：</span>
          </template>
          <template v-else>
            <span class="indent"></span>
          </template>
		  
          <span v-html="item.connName"></span>
        </dd>
      </dl>
    </div>
    <div class="description" @click="toLink">©Copyright 中国载人航天学术大会</div>
    <div class="description" @click="toLink">京ICP备14018798号-6</div>
	
  </div>
</div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState('common', {
      'conferencesInfo': state => state.conferencesInfo
    }),
    ...mapState('conferences', {
      // 友情链接
      blogroll: state => state.blogroll,
      channels: state => state.channels
    }),
    sitemap() {

      return this.channels
    }
  },
  methods: {
    ...mapActions('conferences', [
			'fetchConferencesChannels'
		]),
	  toLink(){
		 const url='https://beian.miit.gov.cn/#/Integrated/index'
		 
		 window.open(url, '_blank');
	  }
  },
  async created() {}
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.page-footer {
  background-color: $--cms-primary-color;

  &__content {
    box-sizing: border-box;
    padding: 30px 50px 0;
    width: $--cms-page-content-width1200;
    margin: 0 auto;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .links {
    font-weight: 600;
    font-size: $--cms-font-size-lg;
    color: #FFFFFF;
    line-height: 28px;
    width: 33.33%;
    // padding-right: 20px;

    .title {
      padding-bottom: 20px;
    }

    .item {
      box-sizing: border-box;
      padding-right: 20px;
      padding-bottom: 20px;
      font-weight: 400;
      font-size: $--cms-font-size;
      color: #FFFFFF;
      line-height: 20px;
      width: 50%;
      float: left;
    }

    a {
      text-decoration: none;
      color: #FFFFFF;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .friends {
    width: 300px;
    margin-left: -50px;

    .item {
      width: 100%;
    }
  }

  .contact {
    width: 450px;

    .item {
      width: 100%;
    }

    .indent {
      display: inline-block;
      width: 5em;
    }
  }

  .description {
    text-align: center;
    color: #FFF;
    font-size: $--cms-font-size;
    line-height: $--cms-line-height;
    padding-bottom: 16px;
	cursor: pointer;
  }

  .qrcode-list {
    overflow: hidden;

    .item {
      float: left;
      width: 150px;
      margin-left: 94px;
    }

    .title {
      padding-top: 20px;
      font-weight: 600;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 22px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 767px) {
  .page-footer__content {
    width: auto;
    padding: 15px 15px 0;
  }

  .page-footer__head {
    flex-direction: column;
  }

  .page-footer .links {
    width: auto;
  }

  .page-footer .friends {
    width: auto;
    margin-left: 0;
  }

  .page-footer .contact .item {
    padding-right: 0;
    padding-bottom: .5em;
    
    .group-title {
      display: block;
      padding-top: 1em;
      padding-bottom: .5em;
    }

    .indent {
      display: none;
    }
  }

  .contact {
    padding-bottom: 1em;
    
    .item:first-of-type .group-title {
      padding-top: 0;
    }
  }
}
</style>
