<template>
  <div class="page-container">
    <div class="page-body">
      <el-descriptions :column="1">
        <el-descriptions-item label="论文题目">{{ thesisInfo.thesisTopic || '--' }}</el-descriptions-item>
        <el-descriptions-item label="关键词">{{ thesisInfo.thesisKeys || '--' }}</el-descriptions-item>
        <el-descriptions-item label="摘要">{{ thesisInfo.thesisAbs || '--' }}</el-descriptions-item>
        <el-descriptions-item label="所投专题">{{ subjectName }}</el-descriptions-item>
        <el-descriptions-item label="稿件">
          <template v-if="thesisInfo.absDocUrl || thesisInfo.secrecyDocUrl || thesisInfo.copyrightDocUrl">
            <div v-if="thesisInfo.absDocUrl"><a :href="`${ baseURL }/system/thesis/downloadFile?key=${ thesisInfo.absDocUrl }`" target="_blank">{{
              absDocName }}</a></div>
            <div v-if="thesisInfo.secrecyDocUrl"><a :href="`${ baseURL }/system/thesis/downloadFile?key=${ thesisInfo.secrecyDocUrl}`" target="_blank">{{
              secrecyDocName }}</a></div>
            <div v-if="thesisInfo.copyrightDocUrl"><a :href="`${ baseURL }/system/thesis/downloadFile?key=${ thesisInfo.copyrightDocUrl}`"
                target="_blank">{{ copyrightDocName }}</a></div>
          </template>
          <template v-else>
            <div>--</div>
          </template>
        </el-descriptions-item>
      </el-descriptions>

      <el-table :data="thesisInfo.authors" border style="width: 100%; margin-bottom: 20px;">
        <el-table-column type="index" label="作者顺序" width="80">
        </el-table-column>
        <el-table-column prop="authorName" label="姓名" width="180">
          <template slot-scope="{row}">
            {{ row.authorName + (row.isComm ? '（通讯作者）' : '') }}
          </template>
        </el-table-column>
        <el-table-column prop="authorUnit" label="单位">
        </el-table-column>
        <el-table-column prop="authorEmail" label="邮箱">
        </el-table-column>
        <el-table-column prop="authorPhone" label="联系方式">
        </el-table-column>
      </el-table>
    </div>
    <div class="page-footer">
      <Space>
        <el-button type="primary" style="width: 110px;" @click="handlePrevStep">上一步</el-button>
        <el-button type="primary" style="width: 110px;" @click="handleSubmit">提交</el-button>
      </Space>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { baseURL } from '@/utils/request';

const fileReg = /([^\/]+\.\w+$)/

export default {
  props: {
    current: Number
  },
  data() {
    return {
      baseURL
    }
  },
  computed: {
    ...mapState('system', {
      thesisInfo: state => state.thesisInfo
    }),
    ...mapState('common', {
      'conferencesInfo': state => state.conferencesInfo
    }),
    ...mapState('conferences', {
      'introduction': state => state.introduction
    }),
    subjectName() {
      const subject = this.introduction.subjects.find(item => this.thesisInfo.subId && item.subId === this.thesisInfo.subId)

      return subject ? subject.subject : '--'
    },
    absDocName() {
      return fileReg.test(this.thesisInfo.absDocUrl) ? decodeURIComponent(this.thesisInfo.absDocUrl.match(fileReg)[1]) : (this.thesisInfo.absDocUrl || '--')
    },
    secrecyDocName() {
      return fileReg.test(this.thesisInfo.secrecyDocUrl) ? decodeURIComponent(this.thesisInfo.secrecyDocUrl.match(fileReg)[1]) : (this.thesisInfo.secrecyDocUrl || '--')
    },
    copyrightDocName() {
      return fileReg.test(this.thesisInfo.copyrightDocUrl) ? decodeURIComponent(this.thesisInfo.copyrightDocUrl.match(fileReg)[1]) : (this.thesisInfo.copyrightDocUrl || '--')
    }
  },
  methods: {
    ...mapActions('conferences', [
      'fetchIntroduction'
    ]),
    ...mapActions('system', [
      'postSystemThesisUpsertAbs'
    ]),
    async handleSubmit() {
      try {
        const isOk = await this.$confirm('确定提交论文摘要吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })

        if (isOk) {
          await this.postSystemThesisUpsertAbs({
            conferencesId: this.conferencesInfo.id,
            ...this.thesisInfo
          })

          this.$message({
            type: 'success',
            message: '论文摘要已提交，可在投稿状态页面查看论文状态。'
          })

          this.$router.push({
            path: '/user/submission/status'
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
    handlePrevStep() {
      this.$emit('onCurrentChange', this.current - 1)
    }
  },
  async created() {
    try {
      await this.fetchIntroduction({
        id: this.conferencesInfo.id
      })
    } catch (e) {
      console.error(e)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.page {
  &-header {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
  }

  &-body {
    min-height: calc(100vh - 320px);
  }

  &-footer {
    display: flex;
    justify-content: center;
  }
}

::v-deep {
  .el-descriptions {
    a {
      color: $--cms-primary-color;
      text-decoration: none;

      &:hover {
        color: $--cms-link-hover-color;
      }
    }
  }

  .el-descriptions .el-descriptions-item__content {
    display: block;
  }

  .el-descriptions-item__label {
    font-weight: 600;
    width: 80px;
    justify-content: flex-end;
  }

  .el-descriptions .el-descriptions-item__cell {
    padding-bottom: 38px;
    line-height: 30px;
  }
}
</style>
