<template>
<el-dialog title="修改密码" :visible.sync="show" width="900px" :destroy-on-close="true">
  <el-form ref="form" :model="formData" :rules="rules" :label-width="formLabelWidth" v-if="show">
    <el-form-item label="输入旧密码" prop="oldPassword">
      <el-input type="password" v-model="formData.oldPassword" autocomplete="off" placeholder="请输入旧密码"></el-input>
    </el-form-item>
    
    <el-form-item label="输入新密码" prop="newPassword">
      <el-input type="password" v-model="formData.newPassword" autocomplete="off" placeholder="请输入新密码"></el-input>
    </el-form-item>
    
    <el-form-item label="再次输入" prop="rePassword">
      <el-input type="password" v-model="formData.rePassword" autocomplete="off" placeholder="再次输入密码"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="show = false">取 消</el-button>
    <el-button type="primary" @click="handleSubmit" :loading="formLoading">确 定</el-button>
  </div>
</el-dialog>
</template>

<script>
import { putSystemUserProfileUpdatePwd } from '@/services/system'

export default {
  name: 'ChangePassword',
  props: {
    visible: Boolean
  },
  data() {
    // 校验密码
		const validatePass = (rule, value, callback) => {
			if (value < 8 || value > 20 || !(/[A-Z]/.test(value) && /[a-z]/.test(value) && /[0-9]/.test(value))) {
				callback(new Error('8-20位，包含大写字母、小写字母、数字三种以上'))
			} else {
				callback()
			}
		}

		// 校验重复密码
		const validateRePass = (rule, value, callback) => {
			if (value !== this.formData.newPassword) {
				callback(new Error('输入不相同'))
			} else {
				callback()
			}
		}

    return {
      show: this.visible,
      formLabelWidth: '100px',
      formLoading: false,
      formData: {
        oldPassword: '',
        newPassword: '',
        rePassword: ''
      },
      rules: {
        oldPassword: [{
          required: true,
          message: '请输入旧密码'
        }],
        newPassword: [{
          required: true,
          message: '请输入新密码'
        }, {
					validator: validatePass,
					trigger: 'blur'
				}],
        rePassword: [{
          required: true,
          message: '请输入确认密码'
        }, {
					validator: validateRePass,
					trigger: 'blur'
				}]
      }
    }
  },
  methods: {
    async handleSubmit() {
      try {
        this.formLoading = true

        const isOk = await this.$refs['form'].validate()

        await putSystemUserProfileUpdatePwd({
          oldPassword: this.formData.oldPassword,
          newPassword: this.formData.newPassword
        })

        this.$message.success('重置密码成功')

        this.show = false
      } catch (e) {
        console.error(e)
      } finally {
        this.formLoading = false
      }
    }
  },
  watch: {
    visible(value) {
      this.show = value
    },
    show(value) {
      this.$emit('update:visible', value)

      if (value) {
        this.formData = {
          oldPassword: '',
          newPassword: '',
          rePassword: ''
        }
      }
    }
  }
}
</script>
