<template>
  <el-container class="layout">
    <el-header height="110px">
      <PageHeader hide-in-lg layout="admin">
        <template slot="menu">
          <AdminSideMenu />
        </template>
      </PageHeader>
      <AdminPageHeader hide-in-sm />
    </el-header>
    <el-container class="layout-container">
      <el-aside width="220px" hide-in-sm>
        <AdminSideMenu />
      </el-aside>
      <el-container>
        <el-main class="layout-main">
          <AdminPageContainer>
            <router-view />
          </AdminPageContainer>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions
} from 'vuex'

import PageHeader from '@/components/PageHeader'
import AdminPageHeader from '@/components/AdminPageHeader'
import AdminSideMenu from '@/components/AdminSideMenu'
import AdminPageContainer from '@/components/AdminPageContainer'

export default {
  name: 'LayoutUserCenter',
  components: {
    PageHeader,
    AdminPageHeader,
    AdminSideMenu,
    AdminPageContainer
  },
  computed: {
    ...mapState('common', {
      'conferencesInfo': state => state.conferencesInfo
    }),
    ...mapGetters('user', [
      'accessToken'
    ])
  },
  async created() {
    if (!this.accessToken) {
      this.$router.replace(`/login?redirect=${this.$route.fullPath}`)

      return false
    }

    try {
      await this.fetchThesisDateStatus({
        conferencesId: this.conferencesInfo.id
      })
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    ...mapActions('common', [
      'fetchConferencesInfo'
    ]),
    ...mapActions('system', [
      'fetchThesisDateStatus'
    ])
  }
}
</script>

<style lang="scss" scoped>
.layout {
  min-height: 100vh;

  &-main {
    padding: 0;
  }
}

::v-deep {
  .el-aside {
    position: relative;
    z-index: 1;
    overflow: visible;
  }
}

@media screen and (max-width: 767px) {
  :deep() {
    .el-header {
      height: auto !important;
    }

    .layout-container {
      padding-top: 15vw;
    }
  }
}
</style>
