<template>
	<div class="login">
		<div class="login-container">
			<div class="login-form">
				<div class="login-form__head">
					<h3 class="login-form__title">注册</h3>
				</div>
				<div class="login-form__body">
					<el-form ref="form" :model="formData" :rules="formRules" label-width="auto">
						<el-form-item prop="userName">
							<el-input v-model="formData.userName" placeholder="用户名"></el-input>
						</el-form-item>
						<el-form-item prop="nickName">
							<el-input v-model="formData.nickName" placeholder="真实姓名"></el-input>
						</el-form-item>
						<el-form-item prop="email">
							<el-input v-model="formData.email" placeholder="邮箱"></el-input>
						</el-form-item>
						<el-form-item prop="phonenumber">
							<el-input v-model="formData.phonenumber" placeholder="手机号"></el-input>
						</el-form-item>
						<el-form-item prop="password">
							<el-input v-model="formData.password" type="password" placeholder="密码"></el-input>
						</el-form-item>
						<el-form-item prop="rePassword">
							<el-input v-model="formData.rePassword" type="password" placeholder="再次输入密码"></el-input>
						</el-form-item>
						<el-form-item prop="code">
							<el-input placeholder="图形验证码" v-model="formData.code" class="form-item__imgcode">
								<template slot="append">
									<img class="form-item__imgcode-img" :src="`data:image/gif;base64,${imgAuth.img}`"
										width="93" height="38" @click="fetchImgCode" title="点击更换验证码" />
								</template>
							</el-input>
						</el-form-item>
						<el-form-item class="form__footer">
							<div class="form__footer-row">
								<el-button type="primary" plain @click="() => $router.go(-1)">取消</el-button>
								<el-button type="primary" @click="handleSubmit" :loading="submitLoading">完成</el-button>
							</div>
							<div class="form__footer-row_end">
								<a href="javascript:;" @click="() => $router.go(-1)">返回</a>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { Message } from 'element-ui'
import AuthCodeButton from '@/components/AuthCodeButton'
import Cookies from 'js-cookie';

export default {
	name: 'RegisterView',
	components: {
		AuthCodeButton
	},
	data() {
		// 校验密码
		const validatePass = (rule, value, callback) => {
			if (value < 8 || value > 20 || !(/[A-Z]/.test(value) && /[a-z]/.test(value) && /[0-9]/.test(value))) {
				callback(new Error('8-20位，包含大写字母、小写字母、数字三种以上'))
			} else {
				callback()
			}
		}

		// 校验重复密码
		const validateRePass = (rule, value, callback) => {
			if (value !== this.formData.password) {
				callback(new Error('输入不相同'))
			} else {
				callback()
			}
		}

		// 校验手机号
		const validatePhone = (rule, value, callback) => {
			if (!/^1\d{10}$/.test(value)) {
				callback('请输入正确的手机号')
			} else {
				callback()
			}
		}

		return {
			submitLoading: false,
			formData: {
				// 用户名
				userName: '',
				// 用户姓名
				nickName: '',
				// 密码
				password: '',
				// 重复密码
				rePassword: '',
				// 图形验证码
				code: '',
				// 邮箱
				email: '',
				// 手机号
				phonenumber: ''
			},
			formRules: {
				// 用户名
				userName: [{
					required: true,
					message: '请输入用户名'
				}],
				// 真实姓名
				nickName: [{
					required: true,
					message: '请输入真实姓名'
				}],
				// 密码
				password: [{
					required: true,
					message: '请输入密码'
				}, {
					validator: validatePass,
					trigger: 'blur'
				}],
				// 重复密码
				rePassword: [{
					required: true,
					message: '请输入确认密码'
				}, {
					validator: validateRePass,
					trigger: 'blur'
				}],
				// 图形验证码
				code: [{
					required: true,
					message: '请输入图形验证码'
				}],
				// 手机号
				phonenumber: [{
					required: true,
					message: '请输入手机号'
				}, {
					validator: validatePhone,
					trigger: 'blur'
				}],
				// 邮箱
				email: [{
					required: true,
					message: '请输入邮箱'
				}, {
					type: 'email',
					message: '请输入正确的邮箱格式'
				}]
			}
		}
	},
	computed: {
		...mapState('user', {
			'imgAuth': state => state.imgAuth
		}),
		authCodeEnabled() {
			return /^1\d{10}$/.test(this.formData.phonenumber) && this.formData.code
		}
	},
	async created() {
		try {
			await this.fetchImgCode()
		} catch (e) {
			console.log(e)
		}
	},
	methods: {
		...mapActions('user', [
			'postRegister',
			'saveToken',
			'fetchUserInfo',
			'fetchImgCode',
			'postSendSms'
		]),
		handleSubmit(values) {
			Cookies.remove('Authorization')
			
			this.$refs['form'].validate(async (valid) => {
				if (valid) {
					try {
						const params = {
							uuid: this.imgAuth.uuid,
							...this.formData
						}

						// delete params.code
						delete params.rePassword

						this.submitLoading = true

						const data = await this.postRegister(params)

						// this.saveToken(data)

						// this.fetchUserInfo()

						Message.success({
							message: '恭喜您注册成功!'
						})

						this.$router.push({
							path: '/register/complete'
						})
					} catch (e) {
						this.fetchImgCode()

						console.error('注册失败', e)
					} finally {
						this.submitLoading = false
					}
				} else {
					this.fetchImgCode()

					return false
				}
			})
		},
		async handleSmsSend() {
			try {
				await this.postSendSms({
					code: this.formData.code,
					uuid: this.imgAuth.uuid,
					phonenumber: this.formData.phonenumber
				})
			} catch (e) {
				console.log('注册失败', e)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
@import '@/styles/common';

img {
	width: 100%;
	height: 100%;
}

.login {
	background: #000 url('@/assets/website/2024/assets/images/user/moon-mask.jpg') no-repeat center top;
	background-size: auto 100%;
	min-height: 800px;
	overflow: hidden;

	&-container {
		width: $--cms-page-content-width;
		margin: 0 auto;
	}

	&-form {
		width: 500px;
		margin: 100px auto;
		background: #FFF;
		box-shadow: 0px 1px 4px 2px #DCDCDC;
		border-radius: 14px;
		overflow: hidden;

		&__head {
			padding: 16px 20px;
			background-color: #F6F6F8;
		}

		&__title {
			font-size: $--cms-font-size-md;
			text-align: center;
			color: $--cms-color-text-base;
			font-weight: 600;
			line-height: 28px;
		}

		&__body {
			padding: 30px 60px;
		}
	}
}

@media screen and (max-width: 767px) {
	@include login-common;
}
</style>