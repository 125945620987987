<template>
  <div class="article__body">
    <template>
      <el-table class="article__body-table" :data="thesisList" :loading="loading" border style="width: 100%"
        :header-cell-style="{ background: '#f0f0f0' }">
        <el-table-column label="论文编号" width="150">
          <template slot-scope="{row}">
            <span>{{ row.thesisNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column label="论文题目">
          <template slot-scope="{row}">
            <span>{{ row.thesisTopic }}</span>
          </template>
        </el-table-column>
        <el-table-column label="专题">
          <template slot-scope="{row}">
            <span>{{ row.subject || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="投稿时间" width="200">
          <template slot-scope="{row}">
            <span>{{ row.createTime | filterDateStr }}</span>
          </template>
        </el-table-column>
        <el-table-column label="投稿状态" width="200">
          <template slot-scope="{row}">
            <span :style="{ color: getDictTypeColor(row.thesisStatus, dict.type.thesis_status) }">{{
              row.thesisStatus | filterDictTypeLabel(dict.type.thesis_status, '--') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" class-name="small-padding fixed-width" width="270">
          <template slot-scope="{row,$index}">
            <el-button :type="row.operationStat !== 1 && row.operationStat !== 2 ? 'info' : 'primary'" size="mini"
              :disabled="row.operationStat !== 1 && row.operationStat !== 2" @click="handleUpdate(row)">修改</el-button>
            <el-button type="primary" plain size="mini" @click="handleDetail(row)">详情</el-button>
            <el-button v-if="!row.bodyDocUrl" type="primary" plain size="mini" @click="handleUpdate(row)"
              :disabled="row.operationStat !== 2">上传全文</el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </div>
</template>

<script>
import {
  mapState
} from 'vuex'
import { getDictTypeColor } from '@/utils'
import { filterDateStr, filterDictTypeLabel } from '@/filters'

export default {
  dicts: ['thesis_status'],
  components: {},
  filters: {
    filterDateStr,
    filterDictTypeLabel
  },
  props: {
    loading: Boolean
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('system', {
      'thesisList': state => state.thesisList
    })
  },
  mounted() {
  },
  methods: {
    getDictTypeColor,
    handleDetail(row) {
      this.$router.push({
        path: `/user/submission/status/detail/${row.thesisId}`,
        params: {}
      })
    },
    handleUpdate(row) {
      this.$router.push({
        path: `/user/submission/update/${row.thesisId}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.article__body {
  background: #fff;
  padding: 20px;
  // margin-top: 20px;

  &-table {
    margin-top: 30px;
    color: #000;
  }
}

::v-deep {

  .el-table .el-table__cell {
    font-size: 14px;
    font-weight: 400;
  }

  .el-table th.el-table__cell {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }

  .el-table .el-table__body td,
  .el-table .el-table__header th {
    border-color: #DCDCDC;
  }

  .el-table .el-table__body tr:hover {
    color: #003F88;
  }
}
</style>
