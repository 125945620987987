<template>
  <div class="side-menu-wrap">
    <ul class="side-menu" @click="$emit('click')">
      <li hide-in-sm v-if="roleNames.includes('editorial')"
        :class="['side-menu-item', ($route.name === 'editorIalUserInfoDetail' || $route.name === 'editorIalUserInfoUpdate') && 'side-menu-item-active']">
        <router-link to="/editorial/userinfo">编辑部个人资料</router-link>
      </li>
      <li hide-in-sm v-if="roleNames.includes('editorial')"
        :class="['side-menu-item', ($route.name === 'editorIalThesisList' || $route.name === 'editorIalThesisDetail') && 'side-menu-item-active']">
        <router-link to="/editorial/thesis/list">编辑部论文管理</router-link>
      </li>
      <li hide-in-sm v-if="roleNames.includes('portal')"
        :class="['side-menu-item', ($route.name === 'submissionCreate' || $route.name === 'submissionUpdate' || $route.name === 'submissionDetail') && 'side-menu-item-active']">
        <router-link to="/user/submission/create">在线投稿</router-link>
      </li>
      <li hide-in-sm v-if="roleNames.includes('portal')"
        :class="['side-menu-item', ($route.name === 'submissionStatus' || $route.name === 'submissionStatusDetail') && 'side-menu-item-active']">
        <router-link to="/user/submission/status">投稿状态</router-link>
      </li>
      <li v-if="roleNames.includes('portal')"
        :class="['side-menu-item', ($route.name === 'attendStatus' || $route.name === 'attendDetail') && 'side-menu-item-active']">
        <router-link to="/user/attend/status">参会状态</router-link>
      </li>
      <li v-if="roleNames.includes('portal')"
        :class="['side-menu-item', ($route.name === 'userInfo' || $route.name === 'ArticleInfoEdit') && 'side-menu-item-active']">
        <router-link to="/user/info">个人资料</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState('user', {
      'userInfo': state => state.userInfo
    }),
    ...mapGetters('user', [
      'roleNames'
    ])
  },
  created() {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.side-menu {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  min-height: 100%;

  &-wrap {
    height: 100%;
  }

  &-item {
    font-weight: 500;
    font-size: $--cms-font-size;
    color: $--cms-color-text-base;
    line-height: 22px;
    padding: 9px 10px 9px 52px;
    cursor: pointer;
    border-right: 3px solid #FFF;

    a {
      display: block;
      text-decoration: none;
      color: $--cms-color-text-base;

      &:hover {
        color: $--cms-link-hover-color;
      }
    }

    &-active {
      background-color: #E6F4FF;
      border-right-color: $--cms-primary-color;

      a,
      a:hover {
        color: $--cms-link-active-color;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .side-menu {
    background: transparent;
    box-shadow: none;
  }

  .side-menu-item {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    height: 70px;
    padding: 0;
    border: none;

    a {
      height: 100%;
      color: #FFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .side-menu-item-active {
    background: #FFF;

    a {
      color: $--cms-primary-color;
    }
  }
}
</style>
