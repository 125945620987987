<template>
	<div class="article__body">
		<div class="article__body-bigTit">作者信息</div>
		<template>
			<el-table class="article__body-table" :data="thesisInfo.authors" border
				:header-cell-style="{ background: '#f0f0f0' }">
				<el-table-column align="left" label="作者顺序" width="95" type="index" />
				<el-table-column prop="authorName" label="姓名">
					<template slot-scope="{row}">
						{{ row.authorName + (row.isComm ? '（通讯作者）' : '') }}
					</template>
				</el-table-column>
				<el-table-column prop="authorUnit" label="单位">
				</el-table-column>
				<el-table-column prop="authorEmail" label="邮箱">
				</el-table-column>
				<el-table-column prop="authorPhone" label="联系方式">
				</el-table-column>
			</el-table>
		</template>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	components: {
	},
	data() {
		return {
		}
	},
	computed: {
		...mapState('system', {
			'thesisInfo': state => state.thesisInfo
		})
	},
	mounted() {
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.article__body {
	background: #fff;
	padding: 20px;
	margin-top: 20px;

	&-bigTit {
		font-size: 20px;
		color: #003F88;
		line-height: 28px;
		font-weight: 500;
	}

	&-table {
		margin-top: 30px;
		color: #000;
	}
}

::v-deep {
	.el-table .el-table__cell {
		font-size: 14px;
		font-weight: 400;
	}

	.el-table th.el-table__cell {
		font-size: 14px;
		font-weight: 500;
		color: #000;
	}

	.el-table .el-table__body td,
	.el-table .el-table__header th {
		border-color: #DCDCDC;
	}
}
</style>
