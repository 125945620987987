<template>
  <div class="waitHope"></div>
</template>

<script>

export default {
  name: 'waitHopeView',
  components: {
  },
  data() {
    return {}
  },
  computed: {
    
  },
  created() {
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.waitHope{
	width: 100%;
	height: 1080px;
	background: url(../../assets/bg.jpg) no-repeat;
	background-size: contain;
	position: fixed;
	top: 0;
}

</style>
