<template>
	<div class="login">
		<div class="login-container">
			<div class="login-form">
				<div class="login-form__head">
					<h3 class="login-form__title">找回密码</h3>
				</div>
				<div class="login-form__body">
					<el-form ref="form" :model="formData" :rules="formRules" label-width="auto">
						<el-steps hide-in-sm="flex" :active="stepActive" finish-status="success" :space="10" simple
							style="margin-bottom: 0">
							<el-step title="手机或邮箱认证"></el-step>
							<el-step title="重置密码"></el-step>
							<el-step title="密码找回完成"></el-step>
						</el-steps>

						<van-steps hide-in-lg :active="stepActive - 1">
							<van-step>手机或邮箱认证</van-step>
							<van-step>重置密码</van-step>
							<van-step>密码找回完成</van-step>
						</van-steps>

						<template v-if="stepActive === 1">
							<el-form-item prop="authMethod" style="margin-bottom: 20px; text-align: center;">
								<el-radio-group v-model="authMethod" size="small" @change="handleMethodChange">
									<el-radio-button label="邮箱"></el-radio-button>
									<el-radio-button label="手机"></el-radio-button>
								</el-radio-group>
							</el-form-item>

							<template v-if="authMethod === '手机'">
								<el-form-item prop="phone" label=" " key="phone">
									<el-input v-model="formData.phone" placeholder="手机号"></el-input>
								</el-form-item>
							</template>

							<template v-if="authMethod === '邮箱'">
								<el-form-item prop="email" label=" " key="email">
									<el-input v-model="formData.email" placeholder="邮箱"></el-input>
								</el-form-item>
							</template>

							<el-form-item prop="code" label=" ">
								<el-input placeholder="图形验证码" v-model="formData.code" class="form-item__imgcode"
									@change="() => {
										$refs['phoneAuthCode'] && $refs['phoneAuthCode'].reset();
										$refs['emailAuthCode'] && $refs['emailAuthCode'].reset();
									}">
									<template slot="append">
										<img class="form-item__imgcode-img"
											:src="`data:image/gif;base64,${imgAuth.img}`" width="93" height="38"
											title="点击更换验证码" @click="fetchImgCode" />
									</template>
								</el-input>
							</el-form-item>

							<template v-if="authMethod === '手机'">
								<el-form-item prop="verifyCode" label=" " class="form-item__authcode">
									<el-input placeholder="验证码" v-model="formData.verifyCode">
										<template slot="append">
											<AuthCodeButton ref="phoneAuthCode" :disabled="!authCodeEnabled"
												@send="handlSendAuthCode" />
										</template>
									</el-input>
								</el-form-item>
							</template>

							<template v-if="authMethod === '邮箱'">
								<el-form-item prop="verifyCode" label=" " class="form-item__authcode">
									<el-input placeholder="验证码" v-model="formData.verifyCode">
										<template slot="append">
											<AuthCodeButton ref="emailAuthCode" :disabled="!authCodeEnabled"
												@send="handlSendAuthCode" />
										</template>
									</el-input>
								</el-form-item>
							</template>
						</template>

						<template v-if="stepActive === 2">
							<el-form-item prop="newPassword" label=" ">
								<el-input v-model="formData.newPassword" type="password" placeholder="密码"></el-input>
							</el-form-item>
							<el-form-item prop="rePassword" label=" ">
								<el-input v-model="formData.rePassword" type="password" placeholder="再次输入密码"></el-input>
							</el-form-item>
						</template>

						<template v-if="stepActive === 3">
							<el-result icon="success" title="密码已重置" subTitle="请使用新密码进行登录">
							</el-result>
						</template>

						<el-form-item class="form__footer">
							<div class="form__footer-row">
								<el-button type="primary" plain @click="handleCancel">取消</el-button>
								<el-button type="primary" @click="handleSubmit">{{ stepActive === 3 ? '完成' : '下一步'
									}}</el-button>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import AuthCodeButton from '@/components/AuthCodeButton'

export default {
	name: 'ForgotView',
	components: {
		AuthCodeButton
	},
	data() {
		// 校验密码
		const validatePass = (rule, value, callback) => {
			if (value < 8 || value > 20 || !(/[A-Z]/.test(value) && /[a-z]/.test(value) && /[0-9]/.test(value))) {
				callback(new Error('8-20位，包含大写字母、小写字母、数字三种以上'))
			} else {
				callback()
			}
		}

		// 校验重复密码
		const validateRePass = (rule, value, callback) => {
			if (value !== this.formData.newPassword) {
				callback(new Error('输入不相同'))
			} else {
				callback()
			}
		}

		// 校验手机号
		const validatePhone = (rule, value, callback) => {
			if (!/^1\d{10}$/.test(value)) {
				callback('请输入正确的手机号')
			} else {
				callback()
			}
		}

		return {
			stepActive: 1,
			authMethod: '邮箱',

			typeMap: {
				'手机': 1,
				'邮箱': 2
			},
			formData: {
				type: '',
				phone: '',
				newPassword: '',
				code: '',
				rePassword: '',
				code: '',
				email: '',
				verifyCode: ''
			},
			formRules: {
				phone: [{
					required: true,
					message: '请输入手机号/邮箱'
				}, {
					validator: validatePhone,
					trigger: 'blur'
				}],
				email: [{
					required: true,
					message: '请输入邮箱',
				}, {
					type: 'email',
					message: '请输入正确的邮箱格式'
				}],
				newPassword: [{
					required: true,
					message: '请输入密码'
				}, {
					validator: validatePass,
					trigger: 'blur'
				}],
				// 重复密码
				rePassword: [{
					required: true,
					message: '请输入确认密码'
				}, {
					validator: validateRePass,
					trigger: 'blur'
				}],
				code: [{
					required: true,
					message: '请输入图形验证码'
				}],
				verifyCode: [{
					required: true,
					message: '请输入收到的验证码'
				}],
			}
		}
	},
	computed: {
		...mapState('user', {
			'imgAuth': state => state.imgAuth
		}),
		authCodeEnabled() {
			return (/^1\d{10}$/.test(this.formData.phone) && this.formData.code) || (this.formData.email && this.formData.code)
		}
	},
	created() {
	},
	beforeMount() {
		this.stepActive = 1
	},
	methods: {
		...mapActions('user', [
			'fetchImgCode',
			'postSendMsg',
			'postResetPwd'
		]),
		// 找回方式改变
		handleMethodChange(value) {
			this.formData = {
				type: this.formData.type,
				phone: '',
				newPassword: '',
				code: '',
				rePassword: '',
				code: '',
				email: '',
				verifyCode: ''
			}

			this.$refs['form'].resetFields()
			this.$refs['form'].clearValidate()
			this.$refs['phoneAuthCode'] && this.$refs['phoneAuthCode'].reset()
			this.$refs['emailAuthCode'] && this.$refs['emailAuthCode'].reset()
		},
		// 提交
		handleSubmit() {
			// 发送验证码
			if (this.stepActive === 1) {
				this.$refs['form'].validate(async (valid) => {
					if (valid) {
						try {
							this.stepActive++
						} catch (e) {
							console.error('发送找回密码消息失败', e)

							this.fetchImgCode()
						}
					}
				})
			}
			// 重置密码
			else if (this.stepActive === 2) {
				this.$refs['form'].validate(async (valid) => {
					if (valid) {
						try {
							const params = {
								type: this.formData.type,
								newPasswprd: this.formData.newPassword,
								verifyCode: this.formData.verifyCode
							}

							params.verifyKey = {
								1: this.formData.phone,
								2: this.formData.email
							}[this.formData.type]

							await this.postResetPwd(params)

							this.stepActive++
						} catch (e) {
							console.error('重置密码失败', e)
						}
					}
				})
			}

			// 完成重置
			if (this.stepActive === 3) {
				this.$router.push({
					path: '/login'
				})
			}
		},
		// 取消
		handleCancel() {
			if (this.stepActive < 3) {
				this.$router.go(-1)
			} else {
				this.$router.push({
					path: '/login'
				})
			}
		},
		// 发送短信验证码
		async handlSendAuthCode() {
			try {
				await this.postSendMsg({
					code: this.formData.code,
					uuid: this.imgAuth.uuid,
					type: this.formData.type,
					verifyKey: {
						'手机': this.formData.phone,
						'邮箱': this.formData.email
					}[this.authMethod]
				})
			} catch (e) {
				this.fetchImgCode()

				console.log('发送短信验证码失败', e)
			}
		}
	},
	watch: {
		authMethod: {
			async handler(value) {
				this.formData.type = {
					'手机': 1,
					'邮箱': 2
				}[value]

				try {
					this.fetchImgCode()
				} catch (e) {
					console.error(e)
				}
			},
			immediate: true
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
@import '@/styles/common';

img {
	width: 100%;
	height: 100%;
}

.login {
	background: #000 url('@/assets/website/2024/assets/images/user/moon-mask.jpg') no-repeat center top;
	background-size: auto 100%;
	min-height: 800px;
	overflow: hidden;

	&-container {
		width: $--cms-page-content-width;
		margin: 0 auto;
	}

	&-form {
		width: 530px;
		margin: 100px auto;
		background: #FFF;
		box-shadow: 0px 1px 4px 2px #DCDCDC;
		border-radius: 14px;
		overflow: hidden;

		&__head {
			padding: 16px 20px;
			background-color: #F6F6F8;
		}

		&__title {
			font-size: $--cms-font-size-md;
			text-align: center;
			color: $--cms-color-text-base;
			font-weight: 600;
			line-height: 28px;
		}

		&__body {
			padding: 30px 30px;
		}

		.el-steps {
			padding-left: 0;
			padding-right: 0;
			background: transparent;
		}

		::v-deep {
			.el-step.is-simple:not(:last-of-type) .el-step__title {
				max-width: 80%;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	@include login-common;
}
</style>