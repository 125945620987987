<template>
  <div class="page">
    <AdminContainerHeader>
      <el-steps class="steps" :active="step">
        <el-step title="论文信息"></el-step>
        <el-step title="作者信息"></el-step>
        <el-step title="摘要提交"></el-step>
        <el-step title="上传全文"></el-step>
      </el-steps>
    </AdminContainerHeader>
    <AdminContainerContent>
      <template v-if="step === 0">
        <ThesisForm :current="0" @onCurrentChange="handleCurrentChange" />
      </template>
      <template v-if="step === 1">
        <AuthorInfo :current="1" @onCurrentChange="handleCurrentChange" />
      </template>
      <template v-if="step === 2">
        <SummaryInfo :current="2" @onCurrentChange="handleCurrentChange" />
      </template>
      <template v-if="step === 3">
        <PaperUpload :current="3" @onCurrentChange="handleCurrentChange" />
      </template>
    </AdminContainerContent>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex'

import AdminContainerHeader from '@/components/AdminContainerHeader'
import AdminContainerContent from '@/components/AdminContainerContent'
import ThesisForm from './components/ThesisForm'
import AuthorInfo from './components/AuthorInfo'
import SummaryInfo from './components/SummaryInfo'
import PaperUpload from './components/PaperUpload'

export default {
  name: 'SubmissionRecord',
  components: {
    AdminContainerHeader,
    AdminContainerContent,
    ThesisForm,
    AuthorInfo,
    SummaryInfo,
    PaperUpload
  },
  data() {
    return {
      step: 0
    }
  },
  computed: {
    ...mapState('system', {
      'thesisInfo': state => state.thesisInfo
    }),
    action() {
      return {
        'submissionCreate': 'create',
        'submissionUpdate': 'update',
        'submissionDetail': 'detail'
      }[this.$route.name]
    }
  },
  created() {
    this.resetThesisInfo()
  },
  methods: {
    ...mapActions('system', {
      resetThesisInfo: 'resetThesisInfo',
      getSystemThesisThesisInfo: 'getSystemThesisThesisInfo'
    }),
    ...mapMutations('system', {
      setThesisInfo: 'setThesisInfo'
    }),
    handleCurrentChange(current) {
      this.step = current
    }
  },
  watch: {
    '$route.params.id': {
      async handler(value) {
        try {
          if (value) {
            const response = await this.getSystemThesisThesisInfo({
              thesisId: value
            })

            if (response.operationStat === 1) {
              this.step = 0
            }

            if (response.operationStat === 2) {
              this.step = 3
            }

            this.setThesisInfo(response)
          }
        } catch (e) {
          console.error(e)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

::v-deep {
  .el-input .el-button--primary {
    background-color: $--cms-primary-color;
    color: #FFFFFF;
    border-radius: 0;
  }

  .tips {
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>
