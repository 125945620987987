import request from '@/utils/request';

// 新闻详情
export function fetchNewsInfo(params) {
  return request({
    url: '/portal/conferences/newsInfo',
    method: 'GET',
    params
  })
}

// 会议议程
export function fetchAgenda(params) {
  return request({
    url: '/portal/conferences/agenda',
    method: 'GET',
    params
  })
}

// 推荐酒店
export function fetchHotel(params) {
  return request({
    url: '/portal/conferences/handbook',
    method: 'GET',
    params
  })
}

// 联系方式
export function fetchConn(params) {
  return request({
    url: '/portal/conferences/contact',
    method: 'GET',
    params
  })
}

// 大会简介
export function fetchIntroduction(params) {
  return request({
    url: '/portal/conferences/introduction',
    method: 'GET',
    params
  })
}

// 新闻动态
export function fetchNews(params) {
  return request({
    url: '/portal/conferences/news',
    method: 'GET',
    params
  })
}

// 搜索
export function fetchSearch(params) {
  return request({
    url: '/portal/conferences/search',
    method: 'GET',
    params
  })
}

// 往届风采
export function fetchPrevious(params) {
  return request({
    url: '/portal/conferences/previous',
    method: 'GET',
    params
  })
}

// 组织架构
export function fetchFrame(params) {
  return request({
    url: '/portal/conferences/frame',
    method: 'GET',
    params
  })
}

// 投稿须知
export function fetchConferencesReadme(params) {
  return request({
    url: '/portal/conferences/readme',
    method: 'GET',
    params
  })
}

// 栏目详情
export function fetchConferencesChannel(params) {
  return request({
    url: '/portal/conferences/channel',
    method: 'GET',
    params
  })
}

// 栏目列表
export function fetchConferencesChannels(params) {
  return request({
    url: '/portal/conferences/channels',
    method: 'GET',
    params
  })
}
