import {
  fetchNewsInfo,
  fetchAgenda,
  fetchHotel,
  fetchConn,
  fetchIntroduction,
  fetchNews,
  fetchSearch,
  fetchPrevious,
  fetchFrame,
  fetchConferencesReadme,
  fetchConferencesChannel,
  fetchConferencesChannels
} from '@/services/conferences'
import dayjs from 'dayjs'

// initial state
// shape: [{ id, quantity }]
const state = () => ({
  newsInfo: {},
  agenda: [],
  hotel: [],
  conn: [],
  readme: {
    content: '',
    conferencesId: null
  },
  introduction: {
    subjects: []
  },
  news: {
    total: 0,
    rows: []
  },
  // 搜索结果
  searchResult: {
    total: 0,
    rows: []
  },
  // 往届风采
  previous: [],
  frame: [],
  // 栏目详情
  channel: {},
  // 栏目列表
  channels: []
})

// getters
const getters = {
}

// actions
const actions = {
  async fetchNewsInfo({ commit, state }, payload) {
    try {
      const data = await fetchNewsInfo(payload)

      if (data.newsTime) {
        data.newsTimeStr = dayjs(data.newsTime).format('YYYY年MM月DD日')
      }
      
      commit('setNewsInfo', data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async fetchAgenda({ commit, state }, payload) {
    try {
      const data = await fetchAgenda(payload)

      commit('setAgenda', data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async fetchHotel({ commit, state }, payload) {
    try {
      const data = await fetchHotel(payload)

      commit('setHotel', data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async fetchConn({ commit, state }, payload) {
    try {
      const data = await fetchConn(payload)

      commit('setConn', data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
    
  },
  async fetchIntroduction({ commit, state }, payload) {
    try {
      const data = await fetchIntroduction(payload)

      commit('setIntroduction', data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 获取新闻列表
  async fetchNews({ commit, state }, payload) {
    try {
      const data = await fetchNews(payload)

      data.rows = data.rows.map(item => {
        item.newsTimeStr = dayjs(new Date(item.newsTime)).format('YYYY-MM-DD')
  
        return item
      })
  
      commit('setNews', data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 获取搜索结果
  async fetchSearch({ commit, state }, payload) {
    try {
      const data = await fetchSearch(payload)

      commit('setSearchResult', data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 获取往届风采
  async fetchPrevious({ commit, state }, payload) {
    try {
      const data = await fetchPrevious(payload)

      commit('setPrevious', data)
      
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async fetchFrame({ commit, state }, payload) {
    try {
      const data = await fetchFrame(payload)

      commit('setFrame', data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 投稿须知
  async fetchConferencesReadme({ commit, state }, payload) {
    try {
      const data = await fetchConferencesReadme(payload)

      commit('setReadme', data || {})

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 栏目详情
  async fetchConferencesChannel({ commit, state }, payload) {
    try {
      const data = await fetchConferencesChannel(payload)

      commit('setChannel', data || {})

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 栏目列表
  async fetchConferencesChannels({ commit, state }, payload) {
    try {
      const data = await fetchConferencesChannels(payload)

      commit('setChannels', data || {})

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
}

// mutations
const mutations = {
  setNewsInfo(state, payload) {
    state.newsInfo = payload
  },
  setAgenda(state, payload) {
    state.agenda = payload
  },
  setHotel(state, payload) {
    state.hotel = payload
  },
  setConn(state, payload) {
    state.conn = payload
  },
  setIntroduction(state, payload) {
    state.introduction = payload
  },
  setNews(state, payload) {
    state.news = payload
  },
  setSearchResult(state, payload) {
    state.searchResult = payload
  },
  setPrevious(state, payload) {
    state.previous = payload
  },
  setFrame(state, payload) {
    state.frame = payload
  },
  setReadme(state, payload) {
    state.readme = payload
  },
  setChannel(state, payload) {
    state.channel = payload
  },
  setChannels(state, payload) {
    state.channels = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}