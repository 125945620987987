<template>
  <div class="page">
    <ArticleInfo />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ArticleInfo from './components/ArticleInfo/index.vue'

export default {
  name: 'UserInfoView',
  components: {
    ArticleInfo,
  },
  data() {
    return {}
  },
  computed: {

  },
  created() {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.page {
  height: 100%;
}
</style>
