import dayjs from 'dayjs'
import { arr2map } from '@/utils'

// 日期筛选器
export const filterDateStr = (value) => {
    return dayjs(value).format('YYYY-MM-DD HH:mm:ss')
}

// 颜色类型筛选器
export const filterTypeColor = (value) => {
  const colorMap = {
      'default': '#606266',
      'primary': '#409eff',
      'success': '#67c23a',
      'info': '#909399',
      'warning': '#e6a23c',
      'error': '#f56c6c'
  }

  return colorMap[value] || '#606266'
}

export const filterDictTypeLabel = (value, dicts, placeholder = '') => {
  const map = arr2map(dicts)

  return map[value] ? map[value].label : placeholder
}

export default {
    filterDateStr,
    filterTypeColor,
    filterDictTypeLabel
}