import { filterTypeColor } from '@/filters'

// 数组转对象
export function arr2obj(arr, keyName = 'value', valName = 'label') {
    const obj = {}

    arr.forEach(i => {
        obj[i[keyName]] = i[valName]
    })

    return obj
}

// 数组转对象
export function arr2map(arr, keyName = 'value') {
    const obj = {}

    arr.forEach(i => {
        const node = {}

        Object.entries(i).forEach(([key, val]) => {
            if (key !== keyName) {
                node[key] = val
            }

            obj[i[keyName]] = node
        })
    })

    return obj
}

// 获取字典对象类型颜色
export function getDictTypeColor(value, dicts) {
    const map = arr2map(dicts)

    return map[value] ? filterTypeColor(map[value].raw.listClass) : null
}