<template>
  <div class="admin-container-header">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AdminContainerHeader'
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.admin-container-header {
  background-color: #F0F0F0;
  padding: 20px 20px;
}
</style>
